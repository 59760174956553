@import '../../theme/Colors.scss';

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 1.25rem;
}

.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $greyDisabled;
  
}

.slider.animate {
  transition: background-color 0.2s ease;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.125rem;
  width: 1.125rem;
  top: 0.0625rem;
  left: 0.0625rem;
  bottom: 0.25rem;
  background-color: $white;
}

// input:checked + .slider {
//   background-color: $slate;
// }
// input:checked:disabled + .slider {
//   background-color: $greyDisabled;
// }

input:focus + .slider {
  box-shadow: 0 0 0.0625rem $slate;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.25rem);
  -ms-transform: translateX(1.25rem);
  transform: translateX(1.25rem);
}

.slider.round {
  border-radius: 2.125rem;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round.animate:before {
  transition: all 0.2s ease;
}
