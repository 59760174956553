@import '../../theme/Colors.scss';

.menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu__item {
  padding: 0.5rem 20px;
  cursor: pointer;
  color: $neutral700;
  border-left: 4px solid transparent;
  transition: all 0.1s ease;
  display: flex;
  align-items: center;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
}

.menu__item:hover,
.menu__active-item li {
  color: $primary900;
  background-color: $neutral50;
  border-color: $primary900;
  font-weight: 600; 
}

.menu__active-item svg {
  color: $primary900;
}

.menu__active-item li,
.menu__active-item li:hover {
  color: $primary900;
  background-color: $neutral50;
}

.menu__list svg {
  margin-right: 20px;
  text-align: center;
}

.menu__title {
  font-weight: 500;
  color: $secondary900;
  padding: 0 1.5rem;
  margin-bottom: 0.5rem;
  //to reset chakra theme provider
  font-size: 13.28px;
  margin-top: 22.178px;
  line-height: 15.2891px;
}