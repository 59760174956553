@import '../../theme/Colors.scss';

.TextField {
  position: relative;
  width: 100%;

  > input {
    width: calc(100% - (2 * 8px) - 2px);
  }

  &-hasStartIcon {
    &:not(.TextField-hasEndIcon) > input {
      width: calc(100% - 40px - 8px - (2 * 1px));
    }

    &.TextField-hasEndIcon > input {
      // width: calc(100% - (2 * 40px) - 2px);
    }

    > input {
      padding-left: 40px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &-hasEndIcon {
    &:not(.TextField-hasStartIcon) > input {
      width: calc(100% - 40px - 8px - (2 * 1px));
    }

    > input {
      padding-right: 40px;
    }
  }

  > .TextField-startIcon,
  > .TextField-endIcon {
    position: absolute;
    top: 0px;
    height: 100%;
    outline: 0px;
    border: 0px;
    background-color: transparent;
    font-size: 1rem;
    color: $grey2;

    > i {
      line-height: 42px;
      width: 40px;
      text-align: center;
      transition: color 0.4s;
      color: inherit;
    }
  }

  > .TextField-startIcon {
    left: 0px;
  }

  > .TextField-endIcon {
    right: 0px;
  }

  &-focus,
  &-hasValue {
    > .TextField-startIcon,
    > .TextField-endIcon {
      color: $slate;
    }
  }

  &-flush {
    > input {
      background: transparent;
      box-shadow: none;
      border-color: transparent;
      outline: none;
    }
  }
}
