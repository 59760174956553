@import 'theme/Colors.scss';

.audContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-areas: 'card'; // "bulkAction delete card"

  width: 100%;
  height: auto;
  cursor: pointer;
}

.audContainer input[type='checkbox'] {
  margin-right: 1rem;
}

.TableRowCard .bulkActions {
  padding-right: 12px;
  height: 0;
}

/* background-color and border-left is dynamically set based on data status passed into component */
.audCard {
  justify-content: center;
  align-items: center;

  border-radius: 0.35rem;
  font-weight: 400;
  min-height: 3.75rem;
  // padding: 0 1rem;
  padding: 0;
  list-style: none;
  margin: 0 0 0.25rem 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.audType,
.audSize,
.audDateCreated,
.audLastUpdated,
.audActions {
  // justify-content: center;
  align-items: center;
}

.audName {
  grid-area: audName;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: $neutral700;
}

.audType {
  grid-area: audType;
}

.audSize {
  grid-area: audSize;
}

.audDateCreated {
  grid-area: audDateCreated;
}

.audLastUpdated {
  grid-area: audLastUpdated;
}

.audCard .audSummary .summaryGroup {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.audActions {
  justify-content: flex-end !important;
  grid-area: audActions;
}

.audCard button {
  font-size: 1.3rem;
  width: 3rem;
  height: 3rem;
}

.audContainer .toggleSwitch {
  margin-right: 1rem;
}

.audCard td {
  align-items: center;
  height: 100%;
  border-left: 1px solid $coolGrey2;
}

.audCard td:first-child {
  min-width: 10rem;
  border-left: 0 solid $coolGrey2;
}

.audCard .audSummary {
  justify-content: flex-start !important;
}

// .audCard li:last-child {
//   padding-left: 0.5rem !important;
//   // min-width: 10rem;
//   // border-left: 0 solid $coolGrey2;
// }

// .audCard li {
//   border-left: 1px solid $coolGrey2;
//   padding-left: 0.5rem;
// }

// .audCard li:first-child {
//   border-left: 0 solid $coolGrey2;
// }

// .audCard li:first-child::before {
//   font-family: "Font Awesome 5 Free";
//   content: "\f58e";
//   font-size: 1rem;
//   font-weight: bold;
//   cursor: pointer;
//   padding-right: 1rem;
//   color: $greyDisabled;
// }

/* ============ Media Queries ============= */
@media only screen and (max-width: 1024px) {
  .audCard {
    gap: 0.5rem;
    height: 4rem;
    grid-template-columns: repeat(4, 1fr) auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'audName audName audName audName'
      'audDateCreated audLastUpdated audSummary audActions';

    height: auto;
    padding: 0.5rem;
  }

  .audCard.readOnly {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'audName audName audName'
      'audDateCreated audLastUpdated audSummary';
  }

  .audCard .audName {
    align-items: flex-start !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    font-size: 0.8rem;
  }

  .audCard .audActions {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .audCard .audSummary,
  .audCard .audDateCreated,
  .audCard .audLastUpdated {
    flex-direction: column;
    align-items: flex-start;
  }

  .audCard .audSummary .summaryGroup {
    height: 1.25rem;
  }

  .audCard .audDateCreated,
  .audCard .audLastUpdated {
    justify-content: center !important;
  }

  .audCard .audSummary {
    justify-content: center !important;
  }

  .audCard .audActions button {
    padding: 0;
    width: auto;
    margin-right: 1rem;
  }
  .fa-trash {
    margin-right: 12px !important;
  }
}
