.main {
  position: relative;
  overflow: hidden;
}

.main:hover .track {
  opacity: 0.75;
}

.wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.inner {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

.track {
  position: absolute;
  top: 0;
  right: 3px;
  cursor: default;
  user-select: none;
  width: 6px;
  min-height: 30px;
  max-height: 100%;
  background: #d2d2d2;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  z-index: 1;
}

.track:hover {
  width: 10px;
  right: 1px;
}
