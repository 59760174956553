@import './Colors.scss';

input,
select,
textarea {
  padding: 0.5rem;
  border: 0.0625rem solid $lighterGrey;
  border-radius: 0.35rem;
  background: white;
  font-size: 0.95rem;
  line-height: 1.5rem !important; // Need to override normalize.css
  color: $darkGrey;
}

input:disabled,
textarea:disabled {
  cursor: not-allowed !important;
  color: $grey2 !important;
  border-color: $neutral100 !important;
}

select:disabled {
  cursor: not-allowed !important;
  color: $grey !important;
  border-color: $neutral100 !important;
}

input:disabled::placeholder,
textarea:disabled::placeholder {
  color: $grey2;
}

input::placeholder,
textarea::placeholder {
  color: $grey2;
}

input,
select,
textarea {
  transition: border-color 0.2s, box-shadow 0.2s;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border: 0.0625rem solid $primary900;
  box-shadow: 0 0 0.25rem $primary900;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  appearance: none;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

label {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

/* Margin top set dynamically in React based on label/no label */
.selectArrow {
  position: relative;
  right: 1rem;
  top: -2rem;
  margin-bottom: -1.25rem;
  text-align: right;
  pointer-events: none;
  font-size: 0.9rem;
}

.customArrow {
  position: absolute;
  right: 1rem;
  pointer-events: none;
  font-size: 0.9rem;
}

.authLogo {
  max-height: 12.5vh;
  margin: 5rem;
}

.authForm {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  margin: 0 auto;
}

.authForm a {
  text-decoration: underline;
  font-weight: bold;
}

.authForm .title {
  text-transform: uppercase;
  font-weight: 300;
}

.authForm {
  text-align: center;
  width: 100%;
}

.authForm input {
  margin: 0.5rem 0;
}

.authForm button {
  margin-top: 0.5rem;
  width: 100%;
}

.authForm .loginOptions {
  width: 100%;
  text-align: center;
}

.nameGroup {
  display: inline-flex;
}

.nameGroup .FName {
  margin-right: 0.5rem;
  width: 50%;
}

.nameGroup .LName {
  width: 50%;
}

.cityStateGroup .city {
  margin-right: 0.5rem;
  width: 66%;
}

.cityStateGroup .state {
  width: 32%;
}

.cityStateGroup .zip {
  margin-left: 0.5rem;
  width: 32%;
}

.footnote {
  font-size: 0.8rem;
  margin-top: 1rem;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 1024px) {
  .authForm .title,
  .title h1 {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 960px) {
  .backBtn {
    padding: 0.65rem 0.65rem 0.65rem 0;
  }
}

.rail {
  display: flex;
  padding: 8px 0px;
  align-items: center;

  &-rule {
    border-bottom: 1px solid $lighterGrey;
  }
}

.flexGrow {
  flex-grow: 1;
}

.fillWidth {
  width: 100%;
}

.formCentered,
.formContainer {
  display: flex;
  justify-content: center;
}

.formTitle {
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 0 3rem 0;
}

.formSection {
  border-radius: 0.35rem;
  padding: 3rem;
  background-color: $white;
  box-shadow: 0 0.25rem 0.5rem $lighterGrey;
}

.formGroup {
  &:not(&-noMargin) {
    margin-bottom: 2rem;
  }
}

.formLabel {
  display: inline-flex;
  flex-direction: row;
  margin: 0 0 1rem 0;
  font-weight: 500;

  .tooltip {
    margin-left: 0.5rem;
  }
}

.formActionsArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: submit;
  margin-top: 1rem;
}
