@import 'theme/Colors.scss';

@import './UserCard/UserCard.scss';

.usersHeader {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: 0.5rem;
  grid-template-areas:
    'left nav right'
    'search search search';

  width: 100%;
  padding: 0.5rem 0;
}

.usersHeader .left button {
  width: auto;
}

.usersHeader .left {
  grid-area: left;
  min-height: 2.67375rem;
}

.usersHeader .nav {
  display: inline-flex;
  grid-area: nav;
}

.usersHeader .right {
  display: inline-flex;
  justify-content: flex-end;
  grid-area: right;
}

.usersHeader .search {
  grid-area: search;
  padding-top: 1.75rem;
  border-top: 0.0625rem solid $lighterGrey;
}

.noUsers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noUsers p {
  margin-top: 0;
}

.noUsers .actionZone {
  width: auto;
}

.modalBody {
  text-align: center;
}

.modalBody button {
  width: 100%;
}

.modalBody img {
  box-shadow: 0 0.15rem 0.25rem $grey;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 960px) {
  .usersHeader {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'left right'
      'nav nav'
      'search search';
  }

  .nav {
    justify-content: center;
  }
}

@media only screen and (max-width: 500px) {
  .usersHeader .left button {
    text-align: left;
    font-size: 0.9rem;
  }
}
