@import 'theme/Colors.scss';

.SearchParameterChip {
  display: inline-block;
  position: relative;
  margin: 0px auto;
  border-radius: 16px;
  height: 32px;
  line-height: 32px;
  padding: 0px 12px;
  background-color: $lighterGrey;
  color: $slate;
  box-shadow: 0px 0px 16px rgba($grey2, 0.125);

  > .SearchParameterChip-remove {
    margin-left: 12px;
    position: relative;
    top: 1px;
    color: $status100;
  }

  > button {
    border: none;
    outline: none;
    padding: 0px;
    background-color: transparent;
    cursor: pointer;
  }
}
