@import 'theme/Colors.scss';

.template-editor {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  // width: 100vw;
  overflow: hidden;
  background-color: $coolGrey;

  .template-editor__content {
    position: relative;
    display: flex;
    height: calc(100vh - 4rem);

    .template-editor__main-content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }
}
