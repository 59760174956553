@import '../../theme/Colors.scss';

.actionZone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: 0.35rem;
  border: 0.0625rem dashed $greyDisabled;
}

.actionZone button {
  width: 100%;
  height: 100%;
}

/* =========================
Image upload styles
========================= */

.imgUpload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
}

.imgUpload label,
.uploadField {
  cursor: pointer;
  box-sizing: border-box;
  height: 100%;
}

.imgUpload button {
  width: 12rem;
  height: auto;
}

.uploadInput {
  width: 100%;
  height: 100%;
}

.uploadField {
  display: none;
}

.fileName {
  font-size: 0.85rem;
  margin-top: 0;
}

.selectText {
  font-weight: bold;
  margin-top: 0;
}