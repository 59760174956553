@import 'theme/Colors.scss';

.ppkgNew {
  &.campaignSection {
    .rail {
      height: 65px;
    }
    .formContainer {
      .newCampaignForm {
        display: grid;
        grid-gap: 1rem;
        grid-auto-columns: 41rem;
        grid-template-rows: repeat(6, auto);
        grid-template-areas: 'title' 'header' 'mainTitle' 'main' 'submit' 'footer';
        height: auto;

        .newCampaignForm__title {
          display: flex;
          justify-content: center;
          grid-area: title;
          font-weight: 700;
          color: $neutral900;
          text-transform: uppercase;
          font-size: 20px;
          margin: 0 0 2rem 0;
        }

        .headerFields {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr 9rem 5rem;
          grid-template-rows: repeat(2, auto);
          grid-template-areas:
            'name maxFulfillment priority'
            'audience audience audience';
          grid-area: header;

          #campaignName {
            display: inline-flex;
            grid-area: name;

            #nameField {
              width: 100%;
            }
          }

          #campaignMaxFulfillment {
            display: grid;
            grid-area: maxFulfillment;
            input {
              width: 8rem;
            }
          }

          #campaignPriority {
            display: grid;
            grid-area: priority;
            input {
              width: 4rem;
            }
          }

          #campaignName input,
          #campaignMaxFulfillment input,
          #campaignPriority input {
            margin-top: 0.5rem;
            padding: 0.5rem;
            border: 0.0625rem solid #e2e2e2;
            border-radius: 0.35rem;
            background: white;
            line-height: 1.5rem;
            font-size: 0.95rem;
            color: #686f7f;
          }

          .title {
            justify-content: flex-start;
          }
        }

        #audienceSelect {
          width: auto;
          grid-area: audience;
          label {
            width: 100%;
          }
        }

        .campaignSectionTitle {
          grid-area: mainTitle;
          margin: 0;
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        }

        .mainContent {
          display: grid;
          grid-gap: 2rem 3rem;
          grid-area: main;
          grid-template-rows: repeat(4, auto);
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            'status status'
            'schedule schedule'
            'adTemplateArea adTemplateArea'
            'creativePreview creativePreview';
          padding: 3rem;
          background-color: white;
          border-radius: 0.35rem;
          box-shadow: 0 0.25rem 0.5rem $lighterGrey;

          .title {
            display: flex;
            justify-content: flex-start;
            font-weight: 500;
          }

          .enableToggle,
          .deliveryLimit {
            display: inline-flex;
          }

          .enableToggle .title,
          .deliveryLimit .title {
            margin-right: 0.5rem;
          }

          .statusSection {
            display: grid;
            grid-area: status;
            grid-gap: 0.5rem 3rem;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, auto);
            grid-template-areas: 'statusOptions statusAttrs';
            border-bottom: 0.0625rem solid $lighterGrey;

            .statusOptions {
              display: flex;
              flex-direction: column;
              grid-area: statusOptions;
              margin-bottom: 2rem;
            }

            .statusAttrs {
              display: inline-flex;
              align-items: flex-start;
              justify-content: flex-end;
              grid-area: statusAttrs;
              margin-bottom: 2rem;
            }
          }

          .scheduleOptions {
            display: grid;
            grid-gap: 0.5rem 3rem;
            grid-area: schedule;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(4, auto);
            grid-template-areas:
              'deliveryOptions deliveryAttrs'
              'frequencyOptions frequencyAttrs'
              'scheduleLeft scheduleLeft'
              'scheduleRight scheduleRight';

            border-bottom: 0.0625rem solid $lighterGrey;

            .scheduleOptionsLeft .title,
            .scheduleOptionsRight .title,
            .deliveryLimit .title,
            .deliveryOptions .title,
            .frequencyOptions .title,
            .frequencyCap .title {
              margin-top: 0;
              margin-bottom: 0.75rem;
            }

            .deliveryOptions input,
            .frequencyOptions input {
              padding: 0.5rem;
            }

            .radioCheckbox {
              margin: 1rem 0;
              input {
                margin-right: 0.5rem;
              }
            }

            .scheduleOptionsLeft {
              grid-area: scheduleLeft;
              margin-top: 1rem;
            }

            .scheduleOptionsRight {
              display: grid;
              gap: 0.5rem;
              grid-template-columns: repeat(2, 1fr);
              grid-area: scheduleRight;
              margin-bottom: 1rem;

              .dateInput label {
                font-weight: 500;
                width: 100%;
              }
            }

            .deliveryOptions {
              display: flex;
              flex-direction: column;
              grid-area: deliveryOptions;
            }

            .deliveryAttrs {
              display: inline-flex;
              align-items: flex-start;
              justify-content: flex-end;
              grid-area: deliveryAttrs;
              padding-top: 2.5rem;
              .radioCheckbox {
                margin: 0;
              }
            }

            .frequencyOptions {
              display: flex;
              flex-direction: column;
              grid-area: frequencyOptions;
            }

            .frequencyAttrs {
              display: inline-flex;
              align-items: flex-start;
              justify-content: flex-end;
              grid-area: frequencyAttrs;
              padding-top: 2.5rem;
            }
          }

          .adTemplateArea {
            display: flex;
            flex-direction: column;
            grid-area: adTemplateArea;

            span {
              margin: 0.25rem 0;
            }

            .title {
              margin-top: 0;
              margin-bottom: 0.75rem;
            }

            .editorCTA {
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              cursor: pointer;
            }
          }

          .creativePreview {
            display: inline-flex;
            align-content: center;
            justify-content: space-evenly;
            grid-area: creativePreview;

            height: auto;
            width: auto;
            border: 0.0625rem solid $lighterGrey;
            padding: 1rem;
            border-radius: 0.35rem;
            box-sizing: border-box;
            cursor: pointer;

            img {
              border-radius: 0.35rem;
              width: auto;
              height: 100%;
              max-height: 12.25rem;
              box-shadow: 0 0.15rem 0.5rem $grey;
            }
          }
        }

        .submit {
          display: flex;
          justify-content: center;
          grid-area: submit;
          margin-top: 1rem;
          & > button {
            width: auto;
          }
        }

        .footnote {
          display: flex;
          justify-content: center;
          grid-area: footer;

          font-size: 0.8rem;
          margin-top: 1rem;
          a {
            color: $slate;
          }
        }
      }
    }
  }
}

.ReactModal__Overlay {
  z-index: 3;
}

#sellPrice {
  margin-left: 1rem;
  input {
    width: 5rem;
  }
}

#cmpTypeSelect,
#cmpTypeSelect label {
  width: 100%;
}

.adCreativeFootnote {
  font-size: 0.75rem;
  margin: 0;
  a {
    color: $primary900 !important; /* Need to overrid dynamic component color */
  }
}

.uploadContent {
  width: 100%;
  max-width: 50rem;
  height: auto;
}

.uploadContent,
.uploadContent .actionZone {
  min-height: 17rem;
  min-width: 24rem;
}

.uploadContent .actionZone .fas {
  font-size: 3rem !important; /* Need to override dynamic component font size */
}

.editorModalContent {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70vw;
  height: 80vh;
  box-sizing: border-box;
}

.previewModalContent {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;

  height: 100%;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  img {
    // width: auto;
    // height: auto;
    // max-height: 80vh;
    // max-width: 70vw;
    border-radius: 0.35rem;
  }
}

.newCampaign-input,
.totalDeliveryLimit,
.adTemplateArea-select {
  padding: 0.5rem;
  border: 0.0625rem solid #e2e2e2;
  border-radius: 0.35rem;
  background: white;
  line-height: 1.5rem;
  font-size: 0.95rem;
  color: #686f7f;
}

#audience-dropdown .chakra-input:focus {
  border: 1px solid #5e2eba;
  box-shadow: 0 0 4px #5e2eba;
}

.selectArrow:focus-within {
  cursor: pointer !important;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 960px) {
  .ppkgNew {
    &.campaignSection {
      .formContainer .newCampaignForm .mainContent {
        padding: 2rem;
        .scheduleOptions {
          grid-gap: 0.5rem;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(4, auto);
          grid-template-areas:
            'deliveryOptions'
            'deliveryAttrs'
            'frequencyOptions'
            'frequencyAttrs'
            'scheduleLeft'
            'scheduleRight';
          .deliveryAttrs,
          .frequencyAttrs {
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .ppkgNew {
    &.campaignSection {
      .formContainer .newCampaignForm .headerFields {
        grid-template-columns: 9rem 5rem 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
          'name name name'
          'audience audience audience'
          'maxFulfillment priority .';

        #campaignName {
          flex-direction: column;
        }
      }
    }
  }

  #sellPrice {
    margin: 1rem 0 0 0;
    input {
      width: auto;
    }
  }
}

@media only screen and (max-width: 414px) {
  .ppkgNew {
    &.campaignSection {
      .formContainer .newCampaignForm .mainContent .scheduleOptions {
        .scheduleOptionsRight {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, auto);
        }
      }
    }
  }
}
