@import '../../../theme/Colors.scss';

.dashboard-graph {
  margin: 35px 0 50px 0;
  background-color: $white;
  padding: 30px;
  border-radius: 24px;

  .dashboard-graph__top-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.75em;

    .dashboard-graph__tabs-wrapper {
      width: auto;
      .dashboard-graph__tabs {
        .dashboard-graph__tab-list {
          border-radius: 8px;
          overflow: hidden;
          border: 1px solid rgba(146, 146, 146, 0.25);
          color: $neutral400;
          .dashboard-graph__tab {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            padding: 12px 24px;
            .dashboard-graph__tab-text {
              display: flex;
              .ldg-content-container {
                width: unset;
              }
            }
          }
        }
      }
    }

    .dashboard-graph__date-range-wrapper {
      z-index: 50;
    }
  }
}

#chartjs-tooltip {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 12px 16px;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  transform: translate(-50%, 0);
  z-index: 60;

  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.1);
    bottom: -6px;
    left: calc(50% - 8px);
    background: $white;
    transform: rotate(45deg);
    z-index: -2;
  }

  &::before {
    content: '';
    width: 30px;
    height: 15px;
    position: absolute;
    background: $white;
    left: calc(50% - 8px);
    bottom: 0;
    z-index: -1;
  }
  .tooltip-title {
    color: $neutral700;
    opacity: 0.6;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  .tooltip-prints {
    color: $neutral700;
    font-size: 16px;
    font-weight: 600;
    line-height: 31px;
  }

  i {
    margin-right: 5px;
    padding: 10px;
    font-size: 12px;
  }

  .tooltip-percent {
    color: $primary900;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 13px;
  }

  .tooltip-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .svg-container {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $primary100;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
}

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

#graph-select-box {
  width: 150px;
}

.personalized-dropdown-item::after {
  content: '';
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 2px;
  height: 15px;
  background: $primary900;
}

@media only screen and (max-width: 992px) {
  .dashboard-graph {
    .dashboard-graph__top-container {
      flex-direction: column;
      gap: 1em;
      margin-bottom: 1em;
      .dashboard-graph__tabs-wrapper {
        width: 100%;
        .dashboard-graph__tabs {
          .dashboard-graph__tab-list {
            flex-direction: column;
            border-radius: 0;
            border: none;
            .dashboard-graph__tab {
              border-radius: 8px;
              border: 1px solid rgba(146, 146, 146, 0.25);
              padding: 8px 12px;
            }
          }
        }
      }

      .dashboard-graph__date-range-wrapper {
        width: 100%;
        display: flex;
        justify-content: right;
      }
    }
  }
}
