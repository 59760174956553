@import "theme/Colors.scss";

.paramGroup {
  display: grid;
  grid-gap: 0.5rem;
  justify-content: center;
  grid-template-columns: 13rem 1fr 2rem;
  grid-template-areas: 'mainParam secondParam removeParam';

  width: 100%;
}

.paramGroup.readOnly {
  grid-template-columns: 13rem 1fr;
  grid-template-areas: "mainParam secondParam";
}

.paramGroup .customOpSelect .customArrow {
  color: $black;
  top: 0.8rem;
  pointer-events: unset;
  cursor: pointer;
}

.customOpSelect input::placeholder {
  color: $slate;
}

.customOpSelect input:focus::-webkit-input-placeholder {
  color: transparent;
}

.customOpList {
  position: absolute;
  z-index: 1;
  box-shadow: 0 0.0625rem 0.25rem $greyDisabled;
  padding: 1rem 0 0;
  background-color: $white;
  min-width: 10rem;
  height: 12rem;
  border-radius: 0.35rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.customOpSelect .customSelect.readOnly::placeholder {
  color: gainsboro;
}

.customOpList .typeTitle {
  font-weight: bold;
  font-size: 1.15rem;
  padding-left: 1rem;
  margin: 0 0 0.5rem;
}

.customOpList ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}

.customOpList li {
  display: flex;
  align-items: center;
  height: 1.75rem;
  padding: 0 0 0 1rem;
  cursor: pointer;
}

.customOpList li.active {
  background-color: $lighterGrey;
}

.customOpList li:hover {
  color: $white;
  background-color: $slate;
}

.paramGroup .rmvParam button {
  position: relative;
  right: 0.5rem;
}

.mainParam label,
.secondGroup label {
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.mainParam {
  display: inline-flex;
  grid-area: mainParam;
  max-width: 14rem;
}

.mainParam input[type="text"] {
  width: 10.9rem;
}

.mainParam .orContainer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  top: -1.85rem;
  height: 3rem;
  width: 1rem;
  border-right: 0;
}

.mainParam .noOrLink {
  border: 0;
}

.mainParam .or {
  position: relative;
  right: 0.7rem;
  padding: 0.15rem;
  background-color: $white;
  color: $greyDisabled;
}

.sectionLink {
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  margin: 0 0 -1.5rem 0;
}

.sectionLink.firstLink {
  margin: 1rem 0 -1.5rem 0;
}

.secondGroup {
  display: inline-flex;
  grid-area: secondParam;
}

.secondParam {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.secondParam label input {
  width: 100%;
  box-sizing: border-box;
}

.opField {
  position: relative;
  margin-right: 0.5rem;
  min-width: 10rem;
}

.paramGroup > .button {
  height: 3rem;
  padding: 0.5rem;
  padding-bottom: 0.6rem;
  grid-area: removeParam;
}