@import '../../theme/Colors.scss';

.checkbox {
  display: flex;
  align-items: center;

  > label {
    margin-left: 8px;
    font-size: 0.8rem;
  }
}

.checkContainer {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    background-color: rgba($coolGrey3, 0.25);
    transition: background-color 0.2s;

    /* Create the checkmark/indicator (hidden when not checked) */
    &::after {
      content: '\f00c';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      position: absolute;
      left: 0px;
      top: -2px;
      padding: 2px;
      width: 12px;
      height: 12px;
      color: white;
      font-size: 12px;
      border-radius: 3px;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  /* On mouse-over, add a grey background color */
  &:hover input:not(:checked) ~ .checkmark {
    background-color: rgba($coolGrey3, 0.5);
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $primary900;
  }

  &:active input:not(:checked) ~ .checkmark {
    background-color: $primary900;
  }

  &:active input:checked ~ .checkmark {
    background-color: $primary500;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark {
    &::after {
      opacity: 1;
    }
  }
}
