@import 'theme/Colors.scss';

$shadow-height: 16px;

.ShadowRule {
  position: relative;
  pointer-events: none;
  height: $shadow-height;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    top: -$shadow-height;
    height: $shadow-height;
    width: 100%;
    box-shadow: 0 0.375rem 0.25rem -0.25rem $lighterGrey;
    z-index: 1;
  }

  &-absolute {
    position: absolute;
    width: 100%;
  }
}
