@import 'theme/Colors.scss';

.font-family {
  ::-webkit-scrollbar {
    height: 15px;
    width: 12.5px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $neutral300;
    border: 3.5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: $white;
  border-right: 1px solid rgba(57, 76, 96, 0.15);

  .font-family__input-container {
    padding: 1.5rem 1.5rem 1rem 1.5rem;

    .font-family__input {
      background-color: $white;
      font-size: 14px;
      border-radius: 4px;
      &:focus {
        border-color: $primary800;
        box-shadow: 0 0 0 1px $primary800;
      }
    }
  }

  .font-family__list {
    position: relative;
    height: calc(100% - (40px + 2.5rem));
    width: 100%;
    overflow-y: auto;

    .list-item__private-header,
    .list-item__public-header {
      .list-item__header-title {
        font-weight: 600;
      }
    }

    .list-item__private-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.5rem;
      font-size: 12px;
      border-top: 1px solid $neutral300;

      .list-item__upload-btn {
        background-color: $neutral200;
        border-radius: 4px;
        font-size: 12px;
        padding: 8px 10px;
        height: auto;
      }
    }
    .list-item__public-header {
      font-weight: 600;
      padding: 1.2rem 1.5rem;
      font-size: 12px;
      border-top: 1px solid $neutral300;
      border-bottom: 1px solid $neutral300;
    }

    .list-item__handwriting-group
      .handwriting-group__accordion
      .handwriting-group__accordion-item {
      border-top: none;
      &:last-child {
        border-bottom: 1px solid $neutral300;
      }

      .handwriting-group__accordion-btn {
        font-size: 15px;

        .handwriting-group__title {
          flex: 1;
          text-align: left;
          font-size: 12px;
          padding-left: 7px;
          color: $secondary900;
        }
      }

      .handwriting-group__accordion-panel {
        background: $neutral100;
        position: relative;
        display: flex;
        align-items: center;
        height: 2.5rem;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        &:hover {
          background: $neutral200;
          cursor: pointer;
        }

        .handwriting-group__font-img {
          padding-left: 1.5rem;
        }
      }
    }

    .list-item__font {
      position: relative;
      display: flex;
      align-items: center;
      height: 2.5rem;
      width: 100%;
      &:hover {
        background: $neutral200;
        cursor: pointer;
      }

      .list-item__font-img {
        padding-left: 1.5rem;
      }
    }
  }
}
