@import '../../theme/Colors.scss';

input,
select,
textarea {
  padding: 0.5rem;
  border: 0.0625rem solid $lighterGrey;
  border-radius: 0.35rem;
  background: white;
  line-height: 1.5rem !important; /* Overriding normalize.css */
  font-size: 0.95rem;
  color: $slate;
}

input:disabled,
select:disabled,
textarea:disabled {
  cursor: default;
  color: $lighterGrey;
  border-color: $neutral100;
}

select {
  appearance: none;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

label {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

/* "top" is set in React based on user label configuration */
.customArrow {
  position: absolute;
  right: 1rem;
  pointer-events: none;
  font-size: 0.8rem;
}

.filedproto-select {
  padding: 7px 16px;
}