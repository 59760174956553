@import 'theme/Colors.scss';

.table {
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: auto 1fr;
  grid-template-areas: "tHead" "tBody";
  width: 100%;
  margin-top: 1rem;
}

.tHead {
  display: grid;
  align-items: center;
  grid-gap: 0.5rem;
  grid-area: tHead;
  // Columns and areas dynamically generated in
  // Table component.

  height: 1.5rem;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 1rem;
  border-radius: 0.35rem;
  box-shadow: 0 0.75rem 0.5rem -0.5rem $lighterGrey;
}

ul.tBody {
  grid-area: tBody;
  width: 100%;
  height: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.tBody .ldg-container {
  width: fit-content;
  height: 1rem;
  margin-top: -1.4rem;
}

ul.tBody li {
  display: grid;
  align-items: center;
  grid-gap: 0.5rem;
  // columns and areas dynamically generated in
  // Table component.

  margin-bottom: 0.35rem;
  height: 1.5rem;
  padding: 1rem;
  border-radius: 0.35rem;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

ul.tBody li:nth-child(even) {
  background-color: $coolGrey2;
}

ul.tBody li:nth-child(odd) {
  background-color: $white;
}

ul.tBody li:hover {
  background-color: $lighterGrey;
}

.liEmpty, .liEmpty:hover {
  cursor: auto;
  background-color: $white !important;
}

.trEmpty {
  text-align: center;
  cursor: auto;
}


/* ============ Media Queries ============= */
@media only screen and (max-width: 1024px) {
  .tHead {
    display: none;
  }

  .tr span {
    display: flex;
    flex-direction: column;
  }

  .mobileTitle {
    font-weight: bold;
  }
}

@media only screen and (max-width: 960px) {
  .tr {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 1rem !important;
    height: fit-content !important;
  }

  .tr span {
    flex-direction: row;
  }

  .trName {
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 414px) {
  .trName {
    flex-direction: column !important;
  }
}