@import 'theme/Colors.scss';

$indicator-width: 5px;

$border-radius: 8px;

$padding-vertical: 8px;
$padding-horizontal: 2px;

$height-small: 64px;

$cell-padding-horizontal: 12px;

$transition-duration: 0.2s;

a.TableCard {
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: $slate;
  }

  &-hover {
    &:hover {
      > .TableCard-tableWrapper > .TableCard-flex {
        color: $deepSlate2;
      }
    }
  }
}

.TableCard {
  display: table-row;
  position: relative;
  color: $slate;
  font-size: 0.9rem;
  font-weight: 300;
  transition: color $transition-duration;

  &-tableLayout {
    display: block;

    .headerLabels {
      visibility: hidden;
      pointer-events: none;
    }
  }

  &-hover {
    &:hover {
      > .TableCard-tableWrapper > .TableCard-flex {
        color: $deepSlate2;
        background-color: $lighterGrey;
      }
    }
  }

  &-focus {
    > .TableCard-tableWrapper > .TableCard-flex {
      background-color: $secondary100;
    }
  }
}

.TableCard-tableWrapper {
  display: table-cell;

  &-blockLayout {
    display: block;
    width: 100%;
  }
}

.TableCard-flex {
  display: flex;
  position: relative;
  margin-bottom: $padding-vertical;
  border-radius: $border-radius;
  background-color: $white;
  // border-left: $indicator-width;
  // border-left: $indicator-width solid $status00;
  transition: color $transition-duration, background-color $transition-duration;
}

.TableCard-indicator {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  transition: background-color 0.4s;
}

.TableCard-table {
  flex-grow: 1;
  border-collapse: collapse;
  table-layout: fixed;

  > tbody {
    > tr {
      &:first-child > td {
        border-top: 0px;
      }

      &:last-child > td {
        border-bottom: 0px;
      }

      > td {
        width: 50%;
        height: 26px;
        line-height: 26px;
        padding: 6px;
        border: 2px solid $coolGrey;

        &:first-child {
          border-left: 0px;
        }

        &:last-child {
          border-right: 0px;
        }

        label {
          font-weight: 400;
        }
      }
    }
  }
}
