@import 'theme/Colors.scss';

.active-campaign-card {
	display: flex;
	padding: 20px 25px;
	align-items: unset;
	height: 100%;
    width: 100%;
	.active-campaign-card__img-wrapper {
		height: 100%;
		width: 30%;
		aspect-ratio : 1 / 1;
		min-width: 60px;
		min-height: 60px;
		background-color: $white;
		.active-campaign-card__img {
			width: 100%;
			height: 100%;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	.active-campaign-card__text-wrapper {
		height: 100%;
		width: 70%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 15px;
		padding: 5px 0;

		.active-campaign-card__text {
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;
			-webkit-line-clamp: 2;
			text-wrap: wrap;
		}

		.active-campaign-card__number {
			font-size: 12px;
			font-weight: 600;
		}
	}
}




@media only screen and (max-width: 600px) { 
	.active-campaign-card {
		align-items: center;
	}
}