@import 'theme/Colors.scss';

.aw-modal__content.chakra-modal__content {
  display: flex;
  align-items: center;
  padding: 32px;

  .aw-modal__header.chakra-modal__header {
    padding: 0;
    color: $neutral900;
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }

  .aw-modal__body.chakra-modal__body {
    color: $neutral500;
    text-align: center;
    padding: 0;
    margin-bottom: 24px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .aw-modal__footer.chakra-modal__footer {
    padding: 0;
  }
}
