@import '../../theme/Colors.scss';

.inventoryHeader {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  grid-template-areas: 'left nav right';

  width: 100%;
  padding: 0.5rem 0;
}

.inventoryHeader .left button {
  width: auto;
}

.inventoryHeader .left {
  grid-area: left;
  min-height: 2.67375rem;
}

.inventoryHeader .nav {
  display: inline-flex;
  grid-area: nav;
}

.inventoryHeader .nav button {
  margin: 0 0.5rem;
  padding: 0.5rem;
  min-width: 10rem;
}

.inventoryHeader .right {
  display: inline-flex;
  justify-content: flex-end;
  grid-area: right;
}

.inventoryHeader .legend {
  font-size: 0.8rem;
}

.inventoryHeader .legend::before {
  font-family: 'Font Awesome 5 Free';
  content: '\f111';
  font-weight: bold;
  font-size: 0.7rem;
  margin-right: 0.5rem;
  color: $status00;
}

.inventoryHeader .search {
  grid-area: search;
  padding-top: 1.75rem;
  border-top: 0.0625rem solid $lighterGrey;
}

.inventoryContainer .noInventory {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inventoryContainer .noInventory p {
  margin-top: 0;
}

.inventoryContainer .noInventory .actionZone {
  width: auto;
}

.inventoryOverview .overviewCard {
  min-width: 3.5rem;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 690px) {
  .inventoryContainer ul.cards {
    flex-direction: row;
  }

  .inventoryLabels {
    display: none;
  }
}
