@import '../../../theme/Colors.scss';

.inventoryLabels {
  display: grid;
  grid-template-columns: 12rem 1fr 6.1rem;
  grid-gap: 0.5rem;
  grid-template-areas:
  "inventoryAddress inventoryShipDate inventoryActions";

  font-weight: 400;
  height: 5rem;
  width: 100%;
  margin: 0 0 2rem 0;
  padding: 0 1rem;
  list-style: none;
  margin-top: -0.75rem;
  box-sizing: border-box;
  box-shadow: 0 0.75rem 0.5rem -0.5rem $lighterGrey;
}

.inventoryLabels li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.inventoryLabels .inventoryAddress {
  grid-area: inventoryAddress;
}

.inventoryLabels .inventoryShipDate {
  grid-area: inventoryShipDate;
}

.inventoryLabels .inventoryActions {
  grid-area: inventoryActions;
}