@import 'theme/Colors.scss';

.userCard .actions button {
  width: auto;
}

.chip {
  display: inline-block;
  position: relative;
  padding: 0px 8px;

  &::before {
    position: absolute;
    left: 0px;
    top: -4px;
    width: calc(100% - (2 * 8px));
    height: 100%;
    background-color: rgb(192, 192, 192);
    border-radius: 4px;
    color: inherit;
    font-size: inherit;
    padding: 4px 8px;
  }
}

.meChip {
  margin-left: 8px;

  &::before {
    content: 'Me';
    background-color: $primary900;
    color: $white;
  }
}
