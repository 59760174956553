@import '../../theme/Colors.scss';

@media only screen and (min-width: 1160px) {
  .pagination {
    padding: 0;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    list-style: none;
    box-sizing: border-box;
    margin-top: 20px;
  }

  .pagination button {
    margin: 0 0.25rem;
    height: auto;
    width: auto;
    min-width: 3rem;
    font-size: 0.8rem;
    font-weight: bold;
  }

  .pagination .prevBtn i,
  .pagination .nextBtn i {
    font-size: 1.25rem !important;
  }
}

@media only screen and (max-width: 1160px) {
  .pagination {
    display: none;
  }
}
