@import 'theme/Colors.scss';

.analytics {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $neutral50;
  width: 100%;
  height: 100%;
  .analytics__header {
    position: relative;
    display: flex;
    width: 100%;
    padding-bottom: 35px;
    gap: 16px;
    flex-wrap: wrap;
    .header__title {
      width: 45%;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: $neutral900;
      display: flex;
      align-items: center;
    }
    .header__date-range-picker {
      width: calc(55% - 85px - 16px - 16px);
      display: flex;
      justify-content: flex-end;
    }
    .header__export-btn {
      display: block;
      width: 85px;
      display: flex;
      justify-content: flex-end;
    }
    .header__export-btn-mobile {
      display: none;
    }
  }

  .analytics__table {
    position: relative;
    background-color: $white;
    padding: 35px;
    border-radius: 24px;
    width: 100%;
    max-height: calc(100% - 70px);
    .table__header {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      .header__notification {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        color: $neutral900;
        background-color: rgba(255, 180, 90, 0.1);
        padding: 9px 8px;
        border-radius: 5px;
        gap: 7px;
      }
      .header__view-btn {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        .header__gridview-sort-dropdown {
          display: flex;
          justify-content: center;
          z-index: 3;
          background: white;
          width: 100%;
        }
        .view-switch__btn {
          border: none;
          border-radius: 5px;
          cursor: pointer;
          width: 33px;
          height: 33px;
        }
      }
    }

    .table__content {
      position: relative;
      height: 100%;
      width: 100%;
      // horizontal scroll bar styling
      /* Works on Chrome, Edge, and Safari */
      ::-webkit-scrollbar {
        height: 17px; /* height of the entire scrollbar */
        width: 17px;
      }
      ::-webkit-scrollbar-track:horizontal {
        background: $neutral100; /* color of the tracking area */
        border-top: 11px solid white;
        border-bottom: 1px solid white;
      }
      ::-webkit-scrollbar-thumb:horizontal {
        background-color: $neutral200; /* color of the scroll thumb */
        border-top: 10px solid white; /* creates padding around scroll thumb */
      }
      ::-webkit-scrollbar-track:vertical {
        background: $neutral100; /* color of the tracking area */
        border-left: 11px solid white;
        border-right: 1px solid white;
      }
      ::-webkit-scrollbar-thumb:vertical {
        background-color: $neutral200; /* color of the scroll thumb */
        border-left: 10px solid white; /* creates padding around scroll thumb */
      }
      .analytics__content-table {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        overflow: scroll;
        .EntityTable-table-table {
          margin-right: 0;
          border-collapse: separate; /* Don't collapse */
          border-spacing: 0;
          // padding-right: 10px;
          // padding-bottom: 10px;
          .EntityTable-head {
            position: sticky;
            top: 0;
            background-color: $white;
            z-index: 3;
            .headerLabels {
              height: 50px;
              font-size: 12px;
              line-height: 14px;
              box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
              .header:first-child {
                position: sticky;
                left: 0;
                z-index: 3;
                /* Apply a left border on the first <td> or <th> in a row */
                border-left: 1px solid $neutral200;
              }
              .header {
                color: $neutral700;
                background-color: $white;
                /* Apply both top and bottom borders to the <th> */
                border-top: 1px solid $neutral200;
                border-bottom: 1px solid $neutral200;
                border-right: 1px solid $neutral200;
              }
              .header-active {
                background-color: $primary50;
                color: $primary900;
              }
            }
          }
          .EntityTable-body {
            .TableRowCard {
              .TableRowCard-cell:first-child {
                position: sticky;
                left: 0;
                z-index: 2;
                /* Apply a left border on the first <td> or <th> in a row */
                border-left: 1px solid $neutral200;
              }
              .TableRowCard-cell {
                height: 51px;
                /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
                border-bottom: 1px solid $neutral200;
                border-right: 1px solid $neutral200;
                .TableRowCard-cell-content {
                  .TableRowCard-indicator {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                  }
                }
                .TableRowCard-cell-active {
                  background-color: $primary50;
                  color: $primary900;
                  font-weight: 600;

                  .qrc__menu-btn {
                    background-color: $white;
                  }
                  .qrc__menu-list {
                    background-color: $white;
                  }
                }
              }
            }
            .TableRowCard:last-child {
              .TableRowCard-cell {
                border-bottom: none;
              }
            }
          }
          .EntityTable-foot {
            background-color: $neutral50;
            border: 1px solid $neutral200;
            .table-foot__table-row {
              position: relative;
              height: 50px;
              font-size: 12px;
              color: $neutral700;
              font-weight: 600;
              .table-foot__table-head {
                position: sticky;
                left: 0;
                z-index: 3;
                background-color: $neutral50;
                /* Apply a left border on the first <td> or <th> in a row */
                border-top: 1px solid $neutral200;
                border-left: 1px solid $neutral200;
                border-right: 1px solid $neutral200;
                border-bottom: 1px solid $neutral200;
              }
              .table-foot__table-cell {
                text-align: center;
                padding: 0 15px;
                border-top: 1px solid $neutral200;
                border-bottom: 1px solid $neutral200;
                border-right: 1px solid $neutral200;
              }
              .table-cell__right {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
  .analytics__table.table-warning {
    .table__content {
      .analytics__content-table {
        height: calc(100% - 50px);
      }
    }
  }
}

// Analytics Grid
.analytics-grid-view__container {
  position: relative;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;

  // Analytics Grid
  .analytics-grid__wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding: 1em 3em 2em 3em;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    gap: 2em;
  }
}

@media only screen and (max-width: 1440px) {
  .analytics-grid-view__container {
    // Analytics Grid
    .analytics-grid__wrapper {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

@media only screen and (max-width: 992px) {
  .analytics-grid-view__container {
    // Analytics Grid
    .analytics-grid__wrapper {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      padding: 1em 2em 2em 2em;
      gap: 1em;
    }
  }
}

@media only screen and (max-width: 800px) {
  .analytics {
    .analytics__table {
      max-height: calc(100% - 63px - 54px);
    }
    .analytics__header {
      padding-bottom: 30px;
      gap: 20px;
      .header__title {
        width: calc(100% - 85px - 20px);
      }
      .header__date-range-picker {
        width: 100%;
        > div {
          width: 100%;
        }
      }
      .header__export-btn {
        display: none;
      }

      .header__export-btn-mobile {
        width: 85px;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 690px) {
  .analytics {
    .analytics__table {
      max-height: calc(100% - 70px - 63px);
      padding: 0;
      .table__header {
        padding-bottom: 0px;
      }
    }
    .analytics__header {
      padding: 30px 25px 20px 25px;
    }
    .analytics__table.table-warning {
      max-height: calc(100% - 70px - 98px);
      .table__content {
        .analytics__content-table {
          height: calc(100% - 10px);
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .analytics-grid-view__container {
    // Analytics Grid
    .analytics-grid__wrapper {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
