@import 'theme/Colors.scss';

.adTemplateDropdown {
  ::-webkit-scrollbar {
    height: 15px;
    width: 15px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .adTemplate__list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.05);
      cursor: pointer;
    }

    .list-item__wrapper {
      display: flex;
      align-items: center;
      height: 44px;
      padding: 0 1rem;

      &.focused {
        background-color: rgba($color: #000000, $alpha: 0.05);
      }

      &.selected {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }

      .list-item__text {
        display: flex;
        flex: 1;
        align-items: center;
        overflow: hidden;
        height: 100%;
        max-height: 44px;
        span {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .list-item__img {
        display: flex;
        height: 100%;
        gap: 4px;

        .list-item__img-wrapper {
          height: 100%;
          aspect-ratio: 1 / 1;
          max-width: 44px;
          max-height: 44px;
          background-color: $white;

          .preview-img {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #adTemplateInput:focus {
    background-color: inherit;
    box-shadow: none;
  }
}
