@import '../../theme/Colors.scss';

.cardHeader {
  display: grid;
  align-items: center;
  grid-gap: 0.5rem;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'cardTitle cardHeaderLine showHide';
  font-size: 0.85rem;
}

.cardHeader p {
  margin: 1rem 0;
}

.cardHeader .cardTitle {
  grid-area: cardTitle;
  font-size: 1.2rem;
  font-weight: bold;
}

.cardHeader .bufferLine {
  border-bottom: 0.0625rem solid $lighterGrey;
  grid-area: cardHeaderLine;
}

.cardHeader i {
  cursor: pointer;
  margin-left: 0.75rem;
}

.cardHeader .showHide {
  grid-area: showHide;
  cursor: pointer;
  font-size: 0.65rem;
  color: $greyDisabled;
}

.dataCard {
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'filters legend range'
    'graph graph graph';

  background-color: $white;
  padding: 2rem;
  border-radius: 0.35rem;
  box-shadow: 0 0.25rem 0.5rem $lighterGrey;
  margin-bottom: 1.5rem;
}

.dataCard .ldg-container {
  position: relative;
  top: 4rem;
  min-height: 17rem;
  grid-area: graph;
}

.dataCard .ldg-content-container {
  grid-area: graph;
}

.dataCard a:link,
.dataCard a:visited {
  color: $primary900;
}

.dataCard .filters {
  display: inline-flex;
  flex-wrap: wrap;
  grid-area: filters;
}

.dataCard .filters label {
  margin-right: 1.5rem;
}

.dataCard h4 {
  margin-top: 0;
}

.dataCard .legend {
  display: inline-flex;
  flex-wrap: wrap;
  grid-area: legend;
}

.dataCard .range {
  display: flex;
  align-items: flex-start;
  grid-area: range;
}

.dataCard .range select {
  min-width: 13rem;
}

.dataCard .kpi select {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.dataCard .graph,
.dataCard .noData {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: graph;
  width: 100%;
  height: 100%;
}

.dataCard .graph {
  min-height: 17rem;
}

.dataCard .noData {
  margin: 0;
}

.dataCard .rangeTitleContainer {
  display: inline-flex;
  justify-content: space-between;
  font-weight: normal;
}

.dataCard .rangeTitleContainer p.useCustom {
  margin: 0 0 0 1rem;
}

.dataCard .rangeTitleContainer label {
  width: auto !important;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 1280px) {
  .dataCard {
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'filters range'
      'legend legend'
      'graph graph';
  }

  .dataCard .graph {
    min-height: auto;
  }
}

@media only screen and (max-width: 960px) {
  .dataCard {
    padding: 1rem;
  }
}

@media only screen and (max-width: 840px) {
  .dataCard {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      'range'
      'filters'
      'legend'
      'graph';
  }

  .dataCard .range label,
  .dataCard .kpi label,
  .dataCard .filters label {
    width: 100%;
    margin-right: 0;
  }

  .dataCard .range {
    flex-wrap: wrap;
    flex-direction: column;
  }

  #startField,
  #endField {
    width: 100%;
  }

  #startField {
    margin-bottom: 1rem;
  }

  .dataCard .dataCardLegend {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }

  .dataCard .value {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 540px) {
  .cardHeader .cardTitle {
    font-size: 0.95rem;
  }

  .dataCard .kpi select {
    min-width: 13rem !important;
  }

  .dataCard .graph {
    min-height: 11rem !important;
  }
}

.dataCardLegend {
  width: auto;
  height: auto;
  min-width: 6rem;
  min-height: 5rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.dataCardLegend .title {
  font-size: 0.85rem;
  margin: 0;
}

.dataCardLegend .value {
  font-size: 3.5rem;
  font-weight: 300;
  margin: 0;
}

.dataCardLegend .line {
  max-width: 2.75rem;
  height: 0.125rem;
  margin: 0.5rem 0 0 0.5rem;
}
