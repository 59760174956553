@import '../../theme/Colors.scss';

.cmpHeader {
  display: flex;
  justify-content: center;
  height: 43px;
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 0.0625rem solid $lighterGrey;
}

.cmpNav {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;

  .filter {
    width: 100%;
  }
}

.cmpLeft {
  grid-area: left;
}

.cmpLeft .cmpSelectAll {
  margin-right: 1rem;
}

.cmpRight {
  grid-area: right;
  justify-content: flex-end;
}

.cmpRight select {
  min-width: 13.5rem;
}

.cmpHeader div {
  display: inline-flex;
  align-items: center;
  width: auto;
}

.cmpHeader button {
  width: auto;
}

.cmpHeader .legendOpen,
.cmpHeader .legendAud {
  font-size: 0.8rem;
  margin-left: 1rem;
}

.cmpHeader .legendOpen::before,
.cmpHeader .legendAud::before {
  font-family: 'Font Awesome 5 Free';
  content: '\f111';
  font-weight: bold;
  font-size: 0.7rem;
  margin-right: 0.5rem;
  color: $status00;
}

.cmpHeader .legendOpen::before {
  color: $primary900;
}

.cmpHeader .legendAud::before {
  color: $secondary900;
}

.cmpBody {
  position: relative;
  width: 100%;
  .EntityTable-table-wrapper {
    padding-top: 0;
    .EntityTable-table-table {
      .EntityTable-head {
        position: sticky;
        top: 0;
        background-color: $white;
        z-index: 3;
        .headerLabels {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}

.cmpBody .content {
  margin-top: 1rem;
}

.cmpBody .noCampaigns {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cmpBody .noCampaigns p {
  margin-top: 0;
}

.cmpBody .noCampaigns .actionZone {
  width: auto;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 500px) {
  .cmpLeft button {
    text-align: left;
    font-size: 0.9rem;
  }
  .flexGrow .TextField {
    font-size: 12px;
  }
}

.campaignsFilterHideDisabled {
  margin-left: 16px;
}
