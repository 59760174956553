@import 'theme/Colors.scss';

.audienceDropdown {
  ::-webkit-scrollbar {
    height: 15px;
    width: 15px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  .audience__list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.05);
      cursor: pointer;
    }

    &.list-item__new-audience {
      display: flex;
      align-items: center;
      height: 44px;
      padding: 0 1rem;
      gap: 0.5rem;
    }

    .list-item__wrapper {
      display: flex;
      align-items: center;
      height: 44px;
      padding: 0 1rem;

      &.focused {
        background-color: rgba($color: #000000, $alpha: 0.05);
      }

      &.selected {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }

      .list-item__text {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
      }

      .list-item__tooltip {
        height: 100%;
      }
    }
  }

  #audienceInput:focus {
    background-color: inherit;
    box-shadow: none;
  }
}
