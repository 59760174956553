@import '../../../theme/Colors.scss';

li.cardSection {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;
  margin: 0 0.5rem 1rem 0.5rem;
}

.cardSection ul.cards {
  display: inline-flex;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardSection li:first-child {
  margin-left: 0;
}

.cardSection li:last-child {
  margin-right: 0;
}

.cardSection li {
  margin: 0 0.5rem 0.5rem;
}

.overviewCard {
  display: grid;
  flex-basis: 100%;
  grid-gap: 1rem 0.5rem;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "icon value"
    "title title";

  min-width: 10.3rem;
  color: $white;
  padding: 1.25rem 1.5rem;
  border-radius: 0.35rem;
  box-shadow: 0 0.25rem 0.5rem $lighterGrey;
}

.overviewCard .icon {
  display: flex;
  margin-top: 0.3rem;
  font-size: 2.25rem;
  grid-area: icon;
}

.overviewCard .value {
  margin-top: 0.45rem;
  font-size: 1.75rem;
  font-weight: 500;
  grid-area: value;
}

.cardSection .header {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: auto 1fr;
  grid-template-areas: "title line";

  margin-bottom: 0.75rem;
}

.cardSection .header .title {
  margin: 0;
  grid-area: title;
  font-size: 1.2rem;
}

.cardSection .header .bufferLine {
  border-bottom: 0.0625rem solid $lighterGrey;
  grid-area: line;
}

.overviewCard .title {
  width: 100%;
  font-size: 1.1rem;
  font-weight: 400;
  grid-area: title;
  margin: 0;
}

.overviewCard .title p {
  margin: 0 0 0.25rem 0;
}

.overviewCard .subTitle {
  font-size: 1rem;
  font-weight: 400;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 768px) {
  li.cardSection {
    width: 100%;
  }

  .cardSection ul.cards {
    flex-direction: column;
  }

  .cardSection li {
    margin: 0 0 0.5rem 0;
  }

  .overviewCard {
    gap: 0.5rem;
    padding: 0.9rem;
  }
}

@media only screen and (max-width: 540px) {
  .cardSection .header .title {
    font-size: 0.95rem;
  }
}