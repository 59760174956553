@import 'theme/Colors.scss';

a,
a:visited,
a:active {
  text-decoration: none;
  color: $darkGrey;
}

a:hover {
  color: $secondary900;
}

main {
  display: grid !important;
  grid-template-columns: 228px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'menu header'
    'menu main';

  margin: 0 auto;
  height: calc(100 * var(--vh));
  //to reset chakra default theme provider
  color: #686f7f;
}

// templateEditor, resetPassword
main.inEditor {
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'main';
}

#manu-nav {
  display: block;
  position: fixed;
  height: 100%;
  z-index: 5;
  left: -250px;
  min-width: 228px;
  padding: 40px 0px;
  background-color: white;
  grid-area: menu;
  transition: all 0.1s ease;
}

#manu-nav.menu-nav__offset {
  left: 0;
}

#manu-nav.inEditor {
  display: none !important;
}

#head-content {
  grid-area: header;
  position: fixed;
  z-index: 2;
  padding: 0 1rem 0 3.25rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  border-bottom: 0.0625rem solid #e2e2e2;
  max-width: -webkit-fill-available;
  background-color: $coolGrey;
  transition: all 0.1s ease;
}

#head-content.inEditor {
  padding: 0;
  z-index: 4;
}

#body-content {
  grid-area: main;
  padding: 50px;
  margin-top: 0;
  transition: all 0.1s ease;
  // max-width: calc(100vw - 16.25rem - 2rem);
}

#body-content.inEditor {
  padding: 0;
  margin: 0;
}

.upload {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  label {
    width: 100% !important;
    max-width: 100% !important;
    height: 35px;
    box-sizing: border-box;
    align-items: revert !important;
  }
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 1280px) {
  // Important tag being used here to override
  // dynamic js styles (for template editor)
  main {
    grid-template-columns: 1fr !important;
    grid-template-areas:
      'header'
      'main' !important;
  }

  #head-content {
    position: relative;
  }

  .hamburger-menu-mobile {
    left: 13rem;
    cursor: pointer;
  }
}

@media only screen and (max-width: 690px) {
  #body-content {
    padding: 0rem 0.75rem 0.75rem;
  }
}
