@import '../../../theme/Colors.scss';

.menu-nav__logo-container {
  text-align: left;
  padding: 10px 20px;
  height: 180px;

  .menu-nav__logo-link {
    width: 100%;

    .menu-nav__logo {
      width: 7rem;
      height: auto;
    }
  }

  .menu-nav__personal-info {
    padding: 8px 12px;
    margin-top: 30px;
    border-radius: 8px;
    border: 1px solid rgba(48, 48, 48, 0.15);
    width: 188px;

    .personal-info__full-name {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: $neutral800;
    }

    .personal-info__work-mail{
      font-size: 12px;
      color: $neutral500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.menu-nav__content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 180px);
}

