@import 'theme/Colors.scss';

.ppkgNew {
  &.audienceSection {
    height: calc(100vh - 100px);
  }

  &.audienceSection,
  &.campaignEditModalSection {
    .rail {
      height: 65px;
    }
    .formContainer {
      .newAudienceForm {
        display: grid;
        grid-gap: 1rem;
        grid-auto-columns: 41rem;
        grid-template-rows: repeat(6, auto);
        grid-template-areas: 'title' 'header' 'mainTitle' 'main' 'submit' 'footer';
        height: auto;

        .newAudienceForm__title {
          display: flex;
          justify-content: center;
          grid-area: title;
          font-weight: 700;
          color: $neutral900;
          text-transform: uppercase;
          font-size: 20px;
          margin: 0 0 2rem 0;
        }

        .headerFields {
          display: grid;
          grid-gap: 1rem;
          // grid-template-columns: 0.65fr 0.35fr; // TODO: Enable when type field is not hidden.
          grid-template-areas: 'name'; // TODO: "name type" when type field is not hidden.
          grid-area: header;
          input {
            padding: 0.5rem;
            border: 0.0625rem solid #e2e2e2;
            border-radius: 0.35rem;
            background: white;
            line-height: 1.5rem;
            font-size: 0.95rem;
            color: #686f7f;
          }
        }

        .paramsSectionTitle {
          grid-area: mainTitle;
          margin: 0;
          margin-top: 1rem;
        }

        .mainContent {
          display: flex;
          flex-direction: column;
          padding-bottom: 1rem;
          border-radius: 0.35rem;
          box-shadow: 0 0.25rem 0.5rem $lighterGrey;
          overflow-y: auto;
          &.campaignContent {
            max-height: 600px;
          }
          &.audienceContent {
            max-height: calc(
              100vh - 65px - 2rem - 72px - 1rem - 74px - 24px - 1rem - 50px -
                1rem - 1rem - 4rem
            );
          }

          .addSet {
            height: 3rem;
            width: 100%;
            margin: 0 auto;
            outline: none;
          }

          .addSet button,
          .addParams button {
            width: 100%;
          }

          .includeParams,
          .excludeParams {
            border-radius: 0.35rem;
            box-shadow: 0 0.15rem 0.25rem $lighterGrey;
            background-color: $white;
          }

          .includeParams {
            margin: 0.5rem 0;
          }

          .excludeParams {
            margin: 2rem 0 0.5rem 0;
          }

          .paramsTitle {
            .tooltip {
              width: fit-content;
              margin-left: 1rem;
              height: auto;
            }
          }
        }

        .submit {
          display: flex;
          justify-content: center;
          grid-area: submit;

          margin-top: 1rem;
        }

        .footnote {
          display: flex;
          justify-content: center;
          grid-area: footer;

          font-size: 0.8rem;
          margin-top: 1rem;
          a {
            color: $slate;
          }
        }
      }
    }
  }
}

#audienceName {
  grid-area: name;
}

#audienceType {
  grid-area: type;
}

#audienceName input,
#audienceType select {
  margin-top: 0.5rem;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 1280px) {
  .ppkgNew {
    &.audienceSection {
      height: calc(100vh - 100px - 64px);
    }
    &.audienceSection,
    &.campaignEditModalSection {
      .formContainer .newAudienceForm .mainContent {
        &.audienceContent {
          max-height: calc(
            100vh - 100px - 64px - 65px - 30px - 2rem - 1rem - 74px - 1rem -
              24px - 1rem - 42px - 2rem
          );
        }
      }
    }
  }
}

@media only screen and (max-width: 670px) {
  .paramGroup {
    display: grid !important;
    gap: 0 0.25rem;
    align-items: center !important;
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'mainParam rmvParam'
      'secondParam rmvParam';

    border-bottom: 0.125rem solid $lighterGrey;
    margin-bottom: 0.5rem;
  }

  .paramGroup.readOnly {
    grid-template-columns: 1fr;
    grid-template-areas:
      'mainParam'
      'secondParam';
  }

  .mainParam {
    max-width: none;
    grid-area: mainParam;
  }

  .mainParam input[type='text'] {
    max-width: calc(100% - 1.05rem);
    width: 100%;
  }

  .mainParam .orContainer {
    top: -2.15rem;
    width: 0;
  }

  .secondParam {
    grid-area: secondParam;
    margin-left: 0 !important;
    max-width: none !important;
  }

  .rmvParam {
    position: relative;
    top: 0.5rem;
    grid-area: rmvParam;
    height: fit-content;
  }

  .paramsTitle {
    display: block !important;
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  .paramGroup .secondGroup {
    flex-direction: column;
  }

  .paramGroup .opField {
    margin: 0;
  }
}

.paramGroup input,
.paramGroup select {
  padding: 0.5rem;
  border: 0.0625rem solid #e2e2e2;
  border-radius: 0.35rem;
  background: white;
  line-height: 1.5rem;
  font-size: 0.95rem;
  color: #686f7f;
}
