@import '../../theme/Colors.scss';

$transition-duration: 0.4s;

.Tabs {
  display: inline-flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;

  > .Tabs-tab {
    min-width: 7rem;

    > button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      cursor: pointer;
      border: 1px solid;
      box-shadow: none;
      transition: color $transition-duration, border-color $transition-duration,
        background-color $transition-duration;
      color: $darkGrey;
      border-color: $lighterGrey;
      background-color: $transparent;
    }

    &:hover > button {
      background-color: $slateHover !important;
      border-color: $slateHover !important;
      color: $white !important;
    }

    &:first-child > button {
      border-radius: 0.35rem 0rem 0rem 0.35rem;
      border-right: none !important;
    }

    &:last-child > button {
      border-radius: 0rem 0.35rem 0.35rem 0rem;
      border-left: none !important;
    }

    &-active > button {
      color: $white;
      border-color: $slate;
      background-color: $slate;
    }
  }
}
