@import '../../../theme/Colors.scss';

.inventoryCard {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-gap: 0.5rem;
  grid-template-columns: 12rem 1fr 5.9725rem;
  grid-template-areas: "address shipDate actions";

  height: 5rem;
  padding: 0 1rem;
  border-radius: 0.35rem;
  background-color: $white;
  margin-bottom: 1rem;
  box-shadow: 0 0.15rem 0.5rem $lighterGrey;
}

.inventoryCard .address {
  grid-area: address;
  font-size: 0.9rem;
  width: 100%;
  padding-left: 3.5rem;
}

.inventoryCard .address p {
  margin: 0;
}

.inventoryCard .shipDate {
  grid-area: shipDate;
  font-size: 0.9rem;
}

.inventoryCard .actions {
  display: inline-flex;
  grid-area: actions;
}

.inventoryCard .actions button {
  width: auto;
  font-size: 1.3rem;
}