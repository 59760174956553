@import '../../theme/Colors.scss';

.audHeader {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'left center right';
  height: 43px;
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 0.0625rem solid $lighterGrey;
}

.audNav {
  grid-area: center;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.audNav button {
  margin: 0 0.5rem;
  padding: 0.5rem;
  min-width: 10rem;
}

.audLeft {
  grid-area: left;
}

.audLeft .audSelectAll {
  margin-right: 1rem;
}

.audRight {
  grid-area: right;
  justify-content: flex-end;
}

.audRight select {
  min-width: 13.5rem;
}

.audHeader div {
  display: inline-flex;
  align-items: center;
  width: auto;
}

.audHeader button {
  width: auto;
}

.audHeader .legend {
  font-size: 0.8rem;
}

.audHeader .legend::before {
  font-family: 'Font Awesome 5 Free';
  content: '\f111';
  font-weight: bold;
  font-size: 0.7rem;
  margin-right: 0.5rem;
  color: $status00;
}

.audBody {
  width: 100%;
  .EntityTable-table-wrapper {
    padding-top: 0;
    .EntityTable-table-table {
      .EntityTable-head {
        position: sticky;
        top: 0;
        background-color: $white;
        z-index: 3;
        .headerLabels {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}

.audBody .content {
  margin-top: 1rem;
}

.audBody .noAudiences {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audBody .noAudiences p {
  margin-top: 0;
}

.audBody .noAudiences .actionZone {
  width: auto;
}

.audTableHeader li {
  padding-left: 0.65rem;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 500px) {
  .audLeft button {
    text-align: left;
    font-size: 0.9rem;
  }
}

.audienceLabels {
  display: grid;
  grid-template-columns: 0.75rem repeat(4, 1fr) 4rem;
  grid-template-areas: '. audHeaderName audHeaderDateCreated audHeaderLastUpdated audHeaderSummary audHeaderActions'; // audHeaderType audHeaderSize

  font-weight: 600;
  height: 5rem;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: 0 0.75rem 0.5rem -0.5rem $lighterGrey;
}

.audienceLabels.readOnly {
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: 'audHeaderName audHeaderDateCreated audHeaderLastUpdated audHeaderSummary'; // audHeaderType audHeaderSize
}

.audienceLabels li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.audHeaderName {
  min-width: 11.8rem;
  grid-area: audHeaderName;
}

.audHeaderType {
  grid-area: audHeaderType;
}

.audHeaderSize {
  grid-area: audHeaderSize;
}

.audHeaderDateCreated {
  grid-area: audHeaderDateCreated;
}

.audHeaderLastUpdated {
  grid-area: audHeaderLastUpdated;
}

.audHeaderSummary {
  grid-area: audHeaderSummary;
}

.audHeaderActions {
  grid-area: audHeaderActions;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 1024px) {
  .audienceLabels {
    display: none;
  }
}
