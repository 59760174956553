// Colors.scss
$darkerGrey: #252525;
$darkGrey: #3b3b3b;
$grey: #666;
$grey2: #9b9b9b;
$greyDisabled: #b3b3b3;
$lightGrey2: #a2a6aa;
$lightGrey3: #838383;
$coolGrey: #f6f7f9;
$coolGrey1: #f9f9f9;
$coolGrey2: #e6e9f0;
$coolGrey3: #9aa3ad;
$lighterGrey: #e2e2e2;
$slate: #686f7f;
$slateHover: #858b99;
$deepSlate: #2f363d;
$deepSlate2: #3c4349;
$deepSlate3: #242a30;
$redLight: #fef8f8;
$pastelYellow: #e7bf3c;
$cyan: #26aac1;
$pastelGreen: #7ebb81;
$magenta: #b23782;
$blue: #296fad;
$grey200: #dee5ef;
$grey300: #a4b0c0;
$grey500: #394a64;
$grey400: #73839c;
$primaryLight: #5e2eba1a;
$lightShadow: rgba(0, 0, 0, 0.07);

// Generic
$white: #ffffff;
$black: #000000;
$transparent: transparent;

// Netural
$neutral50: #F9F9F9;
$neutral100: #F5F5F4;
$neutral200: #E7E5E4;
$neutral300: #D6D3D1;
$neutral400: #A8A29E;
$neutral500: #78716C;
$neutral600: #57534E;
$neutral700: #44403C;
$neutral800: #292524;
$neutral900: #1C1917;

// Primary
$primary50: #FFF0EB;
$primary100: #FFE1D6;
$primary200: #FFD2C2;
$primary300: #FFC3AE;
$primary400: #FFB399;
$primary500: #FDA485;
$primary600: #FB9470;
$primary700: #F8835A;
$primary800: #F57242;
$primary900: #F15F24;

// Secondary
$secondary50: #EBF1FC;
$secondary100: #D8E3F9;
$secondary200: #C4D5F6;
$secondary300: #B1C7F2;
$secondary400: #9EB9EE;
$secondary500: #8BACEA;
$secondary600: #799DE6;
$secondary700: #678FE1;
$secondary800: #5581DC;
$secondary900: #4372D7;

// Status
$status00: #34A853;
$status50: #FBBC05;
$status100: #EA4335;

:export {
  white: $white;
  black: $black;
  transparent: $transparent;
  darkerGrey: $darkerGrey;
  darkGrey: $darkGrey;
  grey: $grey;
  grey2: $grey2;
  greyDisabled: $greyDisabled;
  lightGrey2: $lightGrey2;
  lightGrey3: $lightGrey3;
  coolGrey: $coolGrey;
  coolGrey1: $coolGrey1;
  coolGrey2: $coolGrey2;
  coolGrey3: $coolGrey3;
  lighterGrey: $lighterGrey;
  slate: $slate;
  slateHover: $slateHover;
  deepSlate: $deepSlate;
  deepSlate2: $deepSlate2;
  deepSlate3: $deepSlate3;
  redLight: $redLight;
  pastelYellow: $pastelYellow;
  cyan: $cyan;
  pastelGreen: $pastelGreen;
  magenta: $magenta;
  blue: $blue;
  grey200: $grey200;
  grey400: $grey400;
  grey500: $grey500;
  primaryLight: $primaryLight;
  lightShadow: $lightShadow;
  neutral50: $neutral50;
  neutral100: $neutral100;
  neutral200: $neutral200;
  neutral300: $neutral300;
  neutral400: $neutral400;
  neutral500: $neutral500;
  neutral600: $neutral600;
  neutral700: $neutral700;
  neutral800: $neutral800;
  neutral900: $neutral900;
  primary50: $primary50;
  primary100: $primary100;
  primary200: $primary200;
  primary300: $primary300;
  primary400: $primary400;
  primary500: $primary500;
  primary600: $primary600;
  primary700: $primary700;
  primary800: $primary800;
  primary900: $primary900;
  secondary50: $secondary50;
  secondary100: $secondary100;
  secondary200: $secondary200;
  secondary300: $secondary300;
  secondary400: $secondary400;
  secondary500: $secondary500;
  secondary600: $secondary600;
  secondary700: $secondary700;
  secondary800: $secondary800;
  secondary900: $secondary900;
  status00: $status00;
  status50: $status50;
  status100: $status100;
}
