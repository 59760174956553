@import 'theme/Colors.scss';

.date-range-picker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid $neutral200;
  height: 32px;
  width: 100%;
  background-color: $white;
  cursor: pointer;

  .date-range-picker__date-range-text {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 11px;
  }

  .date-range-picker__list {
    background: $white;
    position: absolute;
    top: 45px;
    right: 0;
    padding-left: 0;
    border-radius: 8px;
    font-size: 12px;
    z-index: 15;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.1);
    overflow: hidden;
    display: flex;
    padding: 24px;
    cursor: default;

    .date-range-picker__aw {
      height: 100%;
      border-right: 1px solid $neutral200;
      padding-right: 24px;
      &.aw__disabled {
        display: none;
      }
      .aw__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $neutral900;
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
        gap: 4px;
      }
      .aw__list-item-wrapper {
        width: 272px;
        height: 130px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;
        .aw__list-item {
          display: flex;
          justify-content: center;
          flex: 1 1 34px;
          border-radius: 8px;
          padding: 10px 8px;
          box-shadow: 0px 1px 2px 0px #1018280a;
          transition: all 0.1s ease;
          cursor: pointer;
        }
      }
    }

    .date-range-picker__dr {
      padding-left: 24px;
      &.aw__disabled {
        padding-left: 0;
      }
      .dr__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $neutral900;
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
      }
      .dr__list-item-wrapper {
        display: flex;
        flex-direction: column;
        width: 120px;
        gap: 8px;
        .dr__list-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          transition: all 0.1s ease;
          padding: 10px 8px;
          border-radius: 8px;
          gap: 8px;
          box-shadow: 0px 1px 2px 0px #1018280a;
          cursor: pointer;
          .dr__label-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      .custom-date-range-picker__wrapper {
        background-color: $white;
        z-index: 4;
        overflow: hidden;
      }
    }
  }
}

// tooltip styling
.chakra-portal {
  .aw__tooltip {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    padding: 16px;
    box-shadow: 0px 8px 24px -3px #1018281a;
  }
}

// day picker rdp styling is in index.scss

.rdp-btns__container {
  position: relative;
  text-align: right;

  .rdp-btns__sub-container {
    padding-top: 16px;
    width: 100%;
    border-top: solid 1px $neutral200;
    display: flex;
    justify-content: flex-end;
    gap: 7px;

    .rdp-btns__btn {
      font-size: 12px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 800px) {
  .date-range-picker {
    .date-range-picker__date-range-text {
      width: 100%;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: 600px) {
  .custom-date-range-picker__wrapper {
    right: unset;
    left: 0;
  }
}

/* Responsive design adjustments */
@media only screen and (max-width: 540px) {
  .rdp {
    top: auto;
  }
}
