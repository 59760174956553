@import 'theme/Colors.scss';

.filter {
  position: relative;
  width: 100%;
}

.filter input {
  width: calc(100% - 3rem - 10px);
  padding-right: 3rem;
}

.filter button {
  position: absolute;
  width: auto;
  height: 100%;
  right: 1.8rem;
  margin-right: -1rem;
  padding: 0;
  opacity: 1;
  transition: opacity 0.4s;

  &:disabled {
    pointer-events: none;
  }
}

.filter button i {
  margin-bottom: 0 !important;
}

.filter button i::before {
  font-size: 0.9rem !important;
}

.TextField > .TextField-endIcon {
  margin-right: 20px;
}