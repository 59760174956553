@import 'theme/Colors.scss';

.template-dropdown {
  ::-webkit-scrollbar {
    height: 15px;
    width: 12.5px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $neutral300;
    border: 3.5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  background: transparent;
  color: #686f7f;
  width: 100%;

  .template_menu-resize-wrapper {
    resize: horizontal;
    overflow: hidden;
    width: 100%;
    max-width: calc(100vw - 100px);
    min-width: 100%;

    .template__list-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.05);
        cursor: pointer;
      }

      &.list-item__new-template {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 0 0 0.95rem;
        gap: 0.7rem;

        &.focused {
          background-color: rgba($color: #000000, $alpha: 0.05);
        }

        &.selected {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }
      }

      .list-item__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 2px 0 0.3rem;

        &.focused {
          background-color: rgba($color: #000000, $alpha: 0.05);
        }

        &.selected {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }

        .list-item__duplicate {
          padding: 0 4px 0 0;
        }

        .list-item__text {
          display: flex;
          flex: 1;
          align-items: center;
          overflow: hidden;
          height: 100%;
          max-height: 44px;
          span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .list-item__img {
          display: flex;
          height: 100%;
          gap: 4px;

          .list-item__img-wrapper {
            height: 100%;
            aspect-ratio: 1 / 1;
            max-width: 44px;
            max-height: 44px;
            background-color: $white;

            .preview-img {
              width: 100%;
              height: 100%;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  #template-input:focus {
    background-color: inherit;
    box-shadow: none;
  }
}

.chakra-input.template-name-edit__input {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  border: none;
  color: $white;
  font-size: 14px;
  border-radius: 2px;
  &.focus-visible {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.chakra-button.template-name-edit__icon-btn {
  color: $white;
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
    border-radius: 2px;
  }
}
