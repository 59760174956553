@import 'theme/Colors.scss';

$indicator-width: 5px;

$border-radius: 8px;

$padding-vertical: 4px;
$padding-horizontal: 2px;

$height-small: 64px;

$cell-padding-horizontal: 15px;

$transition-duration: 0.2s;

a.TableRowCard {
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: $slate;
  }

  &-hover {
    &:hover {
      color: $deepSlate2;
    }
  }
}

.TableRowCard {
  cursor: pointer;
  background-color: transparent;
  display: table-row;
  position: relative;
  color: $slate;
  font-size: 12px;
  font-weight: 400;
  transition: color $transition-duration;
  border-top: 1px solid rgba(48, 48, 48, 0.15);
  &:hover {
    // color: $deepSlate2;
  }

  &-tableLayout {
    display: block;

    .headerLabels {
      visibility: hidden;
      pointer-events: none;
    }
  }

  &-hover {
    &:hover {
      color: $deepSlate2;

      .TableRowCard-cell {
        > .TableRowCard-cell-content {
          background-color: $lighterGrey;
        }
      }
    }
  }

  &-focus {
    .TableRowCard-cell {
      position: relative;

      > div.TableRowCard-cell-content {
        background-color: $secondary100;
      }
    }
  }

  .TableRowCard-wrappedCell {
    align-items: center;
    vertical-align: middle;
  }

  .TableRowCard-cell {
    display: table-cell;
    position: relative;
    // border-left: $padding-horizontal solid;
    // border-color: $coolGrey;
    border: none;
    transition: border-color $transition-duration;
    vertical-align: top;
    padding: 0px;
    // padding-bottom: $padding-vertical;
    height: $height-small - $padding-vertical;

    &-small {
      height: $height-small - $padding-vertical;

      > .TableRowCard-cell-content {
        height: 100%;
      }
    }

    &:first-child {
      border-left: 0px;

      > .TableRowCard-cell-content {
        // border-top-left-radius: $border-radius;
        // border-bottom-left-radius: $border-radius;
      }
    }

    &:last-child {
      > .TableRowCard-cell-content {
        // border-top-right-radius: $border-radius;
        // border-bottom-right-radius: $border-radius;
      }
    }

    > .TableRowCard-cell-content {
      display: flex;
      background-color: $white;
      padding: 0px $cell-padding-horizontal;
      align-items: center;
      transition: background-color $transition-duration;
      min-width: calc(100% - (2 * $cell-padding-horizontal));

      &-has-indicator {
        padding-left: 0px;
      }

      > .TableRowCard-indicator {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        margin-right: $cell-padding-horizontal;
        width: $indicator-width;
        flex-shrink: 0;
        height: 100%;
        transition: background-color $transition-duration;
      }

      &-align-left {
        justify-content: start;
      }

      &-align-center {
        justify-content: center;
      }

      &-align-right {
        justify-content: end;
      }
    }

    &-active {
      background-color: $primary50;
    }
  }
}

.TableRowCard-tableLayout-table {
  position: relative;
  top: -64px;
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  height: calc(100% - $padding-vertical);
}

.TableRowCard-tableLayout-row {
  display: table-row;
}
