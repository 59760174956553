@import 'theme/Colors.scss';

.FullScreen {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  min-height: calc(100 * var(--vh));
  z-index: 10;
  padding: 20px;
  background-color: $coolGrey;
}
