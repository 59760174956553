@import 'theme/Colors.scss';

.analytics-row {
  .analytics-name {
    .analytics-name-container {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      width: calc(100% - 20px);
      height: 100%;

      .analytics-name__img-wrapper {
        width: 40px;
        height: 40px;
        .analytics-name__img {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }

      .analytics-name__text {
        width: calc(100% - 50px);
        font-weight: 400;
        color: $neutral700;
        -webkit-line-clamp: 1;
        cursor: pointer;
      }
    }
  }

  .analytics-qrc {
    .qrc__menu-btn {
      height: 26px;
      padding: 6px 6px 6px 8px;
      background-color: $neutral50;
      font-weight: inherit;
      font-size: 12px;
      .chakra-button__icon {
        margin-inline-start: 4px;
      }
    }
    .qrc__menu-list {
      min-width: 90px;
      background-color: $neutral50;
      box-shadow: 0px 2px 6px 0px #1018280f;
      border: none;
      border-radius: 6px;
      padding: 18px 0px;
    }
    .qrc__menu-item {
      display: block;
      padding: 10px 18px;
      text-align: center;
    }
  }

  .analytics-td-mobile {
    .qrc__menu-btn {
      background-color: $white;
      height: 30px;
      padding: 8px;
      margin-left: 10px;
    }
    .qrc__menu-list {
      min-width: 60px;
      box-shadow: 0px 2px 6px 0px #1018280f;
      border-radius: 6px;
      padding: 18px 0px;
      border: none;
    }
    .qrc__menu-item {
      display: block;
      padding: 10px 18px;
      text-align: center;
    }
  }
}

.chakra-portal > div > div {
  border-radius: 10px !important;
}
