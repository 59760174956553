@import 'theme/Colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&family=Inter:wght@100..900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
@import '@fortawesome/fontawesome-free/css/all.min.css';

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $slate;
  background-color: $coolGrey;
}

// modify date-range-picker global style
.rdp {
  --rdp-cell-size: 30px; /* Size of the day cells. */
  --rdp-caption-font-size: 12px; /* Font size for the caption labels. */
  --rdp-accent-color: #f9f9f9; //neutral50 /* Accent color for the background of selected days. */
  --rdp-background-color: #e7edff; /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #f9f9f9; //neutral50 /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #1c1917; //neutral900/* Color of selected day text */
  background-color: white;
  margin: 0;
  padding-bottom: 10px;
}

//font color for month captions and day labels
.rdp-caption,
.rdp-head {
  color: $neutral900;
}

.rdp-caption {
  border-bottom: solid 1px $neutral200;
  padding: 0 15px 15px 15px;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav,
.rdp-multiple_months .rdp-caption_end .rdp-nav {
  transform: translateY(-75%);
}

.rdp-dropdown {
  option {
    font-size: 12px;
    font-weight: 600;
  }
}

.rdp-day_today:not(.rdp-day_outside) {
  color: $primary900;
}

// day label cells
.rdp-head_cell {
  font-weight: 400;
  margin-top: 20px;
}

// month/year labels
.rdp-caption_label {
  font-size: 14px;
  font-weight: 600;
}

// month arrows
.rdp-nav_button {
  height: 26px !important;
  width: 26px !important;
}

// day
.rdp-day {
  font-size: 12px;
  font-weight: 400;
  color: $neutral900;
}

.rdp-day_range_start,
.rdp-day_range_start:hover,
.rdp-day_range_end,
.rdp-day_range_end:hover {
  border-radius: 50% !important;
  background-color: $primary900 !important;
  color: $white !important;
}

// .rdp-cell:has(.rdp-day_selected .rdp-day_outside):not(.rdp-day_range_start):hover,
// .rdp-cell:has(.rdp-day_selected):has(.rdp-day_outside):not(.rdp-day_range_end):hover {
//   background-color: $neutral50;
//   color: $neutral800;
// }

.rdp-cell:has(.rdp-day_selected):not(.rdp-day_range_start):hover,
.rdp-cell:has(.rdp-day_selected):not(.rdp-day_range_end):hover {
  background-color: $neutral50;
}

.rdp-day_selected:hover {
  border-radius: 50%;
  background-color: #e7edff;
}

.rdp-day_outside {
  color: $neutral400;
}

.rdp-day_outside:hover {
  color: $neutral800;
}

.rdp-cell:has(.rdp-day_range_start) {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  background-color: $neutral50;
}

.rdp-cell:has(.rdp-day_range_end) {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: $neutral50;
}

.rdp-backdrop {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  // position: fixed;
  height: 100%;
}
