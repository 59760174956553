@import 'theme/Colors.scss';

.mainParams {
  display: flex;
  flex-direction: column;
  margin: 0.75rem 0 0 0;
  padding: 1rem;
  border-radius: 0.35rem;
}

.paramsTitle {
  display: flex;
  justify-content: center;
  grid-area: paramsTitle;
  margin: 0 0 1rem 0;
}

.params {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: params;
}

.addParams {
  display: flex;
  justify-content: center;
  grid-area: addParams;
}

.bold {
  font-weight: bold;
}
