@import '../../theme/Colors.scss';

// day picker rdp styling is in index.scss


.rdp-container-buttons {
  margin: 0px 1em 5px 1em;
  padding: 0px 15px 0px 15px;
  text-align: right;
  
}

.rdp-sub-container-buttons {
  // padding: 15px;
  // margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  // padding: 10px;
  float: right;
  width: 100%;
  border-top: solid 1px #E5E7EB;
}

.rdp-container-button {
  margin: 7px;
  font-size: 14px;
}

/* Responsive design adjustments */
@media only screen and (max-width: 540px) {
  .rdp {
    top: auto;
  }
}
