@import 'theme/Colors.scss';

.templateCard.readOnly {
  grid-template-columns: 7rem repeat(2, 1fr);
  grid-template-areas: 'preview name associations';
}

.templateCard td .noThumb {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: $greyDisabled;
}

.templateCard .preview {
  grid-area: preview;
  justify-content: center;
  align-content: center;
  height: 4rem;
  width: auto;
}

.templateCard .preview img {
  height: 4rem;
  width: auto;
  box-shadow: 0 0.1rem 0.15rem $grey;
}

.templateCard .actions button {
  width: auto;
  font-size: 1.3rem;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 1024px) {
  .templateCard {
    gap: 0.5rem;
    height: auto;
    padding: 0.5rem;
  }

  .templateCard li {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .templateCard .nameGroup {
    flex-direction: column;
    font-weight: bold;
  }

  .templateCard .actions {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 860px) {
  .templateCard {
    gap: 0.5rem 2rem;
    grid-template-columns: 7rem 1fr auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'preview name actions'
      'preview associations actions';
  }

  .templateCard.readOnly {
    grid-template-columns: 7rem 1fr;
    grid-template-areas:
      'preview name'
      'preview associations';
  }
}

@media only screen and (max-width: 520px) {
  .templateCard {
    grid-template-columns: 6rem 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'preview name'
      'preview associations'
      'preview actions';
  }

  .templateCard.readOnly {
    grid-template-areas:
      'preview name'
      'preview associations'
      'preview .';
  }
}
