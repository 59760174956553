@import 'theme/Colors.scss';

.EntityTable-table-wrapper {
  position: relative;
  min-height: 40px;
  max-width: 100vw;
  margin: 0 -1rem;
  padding: 1rem;
  padding-bottom: 0;

  // &-tableLayout {
  //   overflow-x: auto;
  // }
}

.EntityTable-table-table {
  position: relative;
  width: 100%;

  table-layout: fixed;
  border-collapse: collapse;
  margin-right: 1rem;

  &-headHidden {
    margin-top: -80px;
  }
}

.EntityTable-progress {
  position: absolute;
  left: calc((100% - 40px) / 2);
  top: calc(66px + ((100% - 66px - 40px) / 2));
  width: 40px;
  height: 40px;
  pointer-events: none;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.2s;

  &-active {
    opacity: 1;
  }
}

.EntityTable-head {
  &-cardLayout {
    > tr > td {
      text-align: center;

      > .SearchParameterChip {
        margin-bottom: 16px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }

  .headerLabels {
    height: 48px;
  }
}

.EntityTable-body {
  opacity: 1;
  transition: opacity 0.2s;

  &-requesting {
    opacity: 0.5;
  }
}

.EntityTable-foot {
  position: sticky;
  bottom: 0;
  z-index: 3;
}

.EntityTable-table-footer {
  display: flex;
  justify-content: center;
}

.EntityTable-error {
  position: relative;
  height: 250px;
}

.EntityTable-error-image {
  position: absolute;
  left: 10%;
  top: 10%;
  width: 80%;
  height: 80%;
  background-image: url('../../static/images/logo-un.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  filter: grayscale(100%);
  opacity: 0.075;
}

.EntityTable-error-title {
  display: block;
  position: relative;
  text-align: center;
  margin: 0 auto;
  color: $grey2;

  code {
    background-color: rgb(160, 160, 160);
    padding: 2px 6px;
    border-radius: 4;
    color: white;
  }
}

.EntityTable-noResults {
  white-space: nowrap;
}
