@import '../../../theme/Colors.scss';

hr {
  margin: 32px 0px;
  border: none;
  border-top: 1px solid $lighterGrey;
}

#userInviteForm {
  margin-top: 24px;
  .formRow {
    display: flex;
    width: 100%;

    > input {
      flex-grow: 1;
    }

    > button {
      border-radius: 5.6px;
      margin-left: 12px;
    }
  }
  .divider {
    margin-top: 24px;
  }
}
