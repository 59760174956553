@import '../../../theme/Colors.scss';

.analyticsHeader {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "backBtn analyticsTabs activeName";

  width: 100%;
  margin-bottom: 0.5rem;
}

.analyticsHeader .activeName {
  grid-area: activeName;
}

.analyticsHeader .backBtn {
  grid-area: backBtn;
}

.analyticsHeader .analyticsTabs {
  grid-area: analyticsTabs;
}

.analyticsHeader .activeName h2 {
  margin: 0;
}

.analyticsHeader .backBtn button {
  width: auto;
}

.analyticsHeader .activeName {
  text-align: right;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 960px) {
  .analyticsHeader {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    grid-template-areas:
      "backBtn"
      "activeName"
      "analyticsTabs";
  }

  .activeName {
    text-align: left !important;
  }
}