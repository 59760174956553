@import 'theme/Colors.scss';

$cell-padding-horizontal: 12px;
$indicator-width: 5px;

.headerLabels {
  height: 64px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.headerLabels .header {
  height: 100%;
  white-space: pre;
  padding: 0px $cell-padding-horizontal;
  align-items: center;
  font-weight: 600;

  &.header-align-left {
    text-align: left;
  }

  &.header-align-center {
    justify-content: center;
    vertical-align: middle;
  }

  &.header-align-right {
    text-align: right;
  }

  &-hasIndicator {
    padding-left: $cell-padding-horizontal + $indicator-width;
  }
  .resizer {
    background: transparent;
    cursor: col-resize;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 0.2rem;
  }
}

.headerLabels .header.headerSortable {
  cursor: pointer;
}

.headerLabels .header i::before {
  margin-right: 0.5rem;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 1160px) {
  .headerLabels {
    font-size: 0.9rem;
  }
}
