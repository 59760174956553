@import '../../theme/Colors.scss';

ul.overview {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  height: auto;
  margin: 1rem 0;
  padding: 0;
  list-style: none;
}

ul.overview .title {
  font-size: 1.25rem;
  font-weight: bold;
}

.top-dashboard {
  position: relative;
  min-height: 250px;
  .welcome-msg-container {
    position: relative;
    flex-direction: row;
    overflow: hidden;
    display: flex;

    .welcome-msg-wrapper {
      width: calc(100% - 250px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 1rem 3rem;
    }

    .welcome-msg__img {
      display: block;
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      width: 250px;
    }
  }

  .cta-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    padding: 1.875rem 0;
    width: 300px;
    .cta-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
    }
  }
}

.cta-btn-container {
  display: block;
  z-index: 2;
}

/* ============ Media Queries ============= */

@media only screen and (max-width: 1280px) {
  .top-dashboard {
    .cta-container {
      width: 300px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .top-dashboard {
    .cta-container {
      width: 100%;
      .cta-wrapper {
        align-items: center;
        text-align: center;
        .cta-btn-container {
          display: block;
          justify-content: center;
        }
      }
      .cta__guide-img {
        position: absolute;
        width: 150px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  ul.overview .title {
    font-size: 0.95rem;
  }

  .top-dashboard {
    margin-top: 2rem;
    .welcome-msg-container {
      .welcome-msg-wrapper {
        width: 100%;
      }
      .welcome-msg__img {
        display: none;
      }
    }
    .cta-container {
      width: 100%;
    }
  }
}
