@import 'theme/Colors.scss';

#userInfoForm {
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    'title'
    'infoTitle'
    'infoSection'
    'submit';

  max-width: 41rem;
  margin: 0 auto;
}

.formTitle {
  display: flex;
  justify-content: center;
  grid-area: title;
}

.formTitle h1 {
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 0 3rem 0;
}

.fullWidth {
  width: 100%;
}

#userInfoForm .userFormContainer {
  display: grid;
  grid-area: infoSection;
  gap: 2rem 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'fName lName'
    'password password'
    'password_confirmation password_confirmation'
    'email email';
}

#userInfoForm .infoSectionTitle {
  grid-area: infoTitle;
  margin: 0;
}

#userInfoForm .title {
}

#userInfoForm #fName {
  grid-area: fName;
}

#userInfoForm #lName {
  grid-area: lName;
}

#userInfoForm #email {
  grid-area: email;
}

#userInfoForm #password {
  grid-area: password;
}

#userInfoForm #password_confirmation {
  grid-area: password_confirmation;
}

#userInfoForm .submitArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: submit;
  margin-top: 1rem;
}

#userInfoForm label span {
  font-weight: bold;
}
