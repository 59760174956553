@import 'theme/Colors.scss';

.templatesHeaders {
  gap: 0.5rem;
  width: inherit;
  padding: 0 1rem;
}

.templatesHeader {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: 0.5rem;
  grid-template-areas:
    'left nav right'
    'search search search';
  width: 100%;
  padding: 0.5rem 0;
}

.templatesHeader .left button {
  width: auto;
}

.templatesHeader .left {
  grid-area: left;
  min-height: 2.67375rem;
}

.templatesHeader .nav {
  display: inline-flex;
  grid-area: nav;
}

.templatesHeader .right {
  display: inline-flex;
  justify-content: flex-end;
  grid-area: right;
  line-height: 43px;
}

.templatesHeader .legend {
  font-size: 0.8rem;
}

.templatesHeader .legend::before {
  font-family: 'Font Awesome 5 Free';
  content: '\f111';
  font-weight: bold;
  font-size: 0.7rem;
  margin-right: 0.5rem;
  color: $status00;
}

.templatesHeader .search {
  grid-area: search;
  padding-top: 8px;
  border-top: 0.0625rem solid $lighterGrey;
}

.templatesHeader .search {
  position: relative;
}

.templatesContainer .noTemplates {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.templatesContainer .noTemplates p {
  margin-top: 0;
}

.templatesContainer .noTemplates .actionZone {
  width: auto;
}

.modalBody {
  text-align: center;
}

.modalBody button {
  width: 100%;
}

.modalBody img {
  box-shadow: 0 0.15rem 0.25rem $grey;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 960px) {
  .templatesHeader {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'left right'
      'nav nav'
      'search search';
  }
  .nav {
    justify-content: center;
  }
  .TableRowCard-indicator {
    margin-right: 2px !important;
  }
}

@media only screen and (max-width: 500px) {
  .templatesHeader .left button {
    text-align: left;
    font-size: 0.9rem;
  }
  .TableRowCard-indicator {
    margin-right: 2px !important;
  }
}
