@import '../../theme/Colors.scss';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modalBG {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modalWin {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "modalHeader" "modalBody" "modalFooter";

  position: relative;
  z-index: 20;
  width: auto;
  min-width: 20rem;
  height: auto;
  min-height: 10rem;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.35rem;
  background-color: $white;
}

.modalHeader {
  display: inline-flex;
  grid-area: modalHeader;

  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  justify-content: space-between;
}

.modalTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.closeModalIcon {
  font-size: 1.25rem;
  cursor: pointer;
}

.modalBody {
  grid-area: modalBody;
}

.modalBody img {
  max-width: calc(100vw - 30%);
  max-height: calc(100vh - 24rem);
}

.modalFooter {
  display: flex;
  justify-content: center;
  grid-area: modalFooter;
  font-size: 0.9rem;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 1024px) {
  .modalBody img {
    max-width: 100%;
    max-height: 30.25rem;
  }
}