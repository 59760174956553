@import 'theme/Colors.scss';

.template-editor__zoom {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 100;

  .zoom__slider-btn {
    display: flex;
    align-items: center;
  }

  .chakra-popover__content.zoom__popover-content {
    width: auto;
    background: $white;
    .zoom__ratio-options {
      width: auto;
      .zoom__ratio-option {
        display: flex;
        align-items: center;
        text-align: center;
        padding: 8px 1rem;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: $neutral200;
        }
      }
    }
  }
}
