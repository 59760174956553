@import 'theme/Colors.scss';

.tooltip {
  position:relative;
  width: auto;
  height: auto;
  cursor: pointer;
}

.tooltip span {
  display:none;
  position: relative;
  z-index: 10;
  width: auto;
  max-width: 12rem;
  height: auto;
  font-size: 0.85rem;
  color: $white;
  text-align: center;
  padding: 0.75rem;
  background-color: $slate;
  border-radius: 0.35rem;
  box-shadow: 0 0.25rem 0.5rem $grey;
}

.tooltip span.fullWidth {
  max-width: unset;
}

.tooltip:hover span {
  display:block;
  position:fixed;
  overflow:hidden;
}