@import 'theme/Colors.scss';

#flash-container {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  width: auto;
}

.flashMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 0 1rem 0;
  z-index: 999;
  max-width: 20rem;
  min-height: 4rem;
  padding: 0 1rem;
  border-radius: 0.35rem;
  box-shadow: 0 0.25rem 0.5rem grey;
  color: $white;
}

.flash-enter {
  opacity: 0;
  transform: scale(0.9);
}
.flash-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.flash-exit {
  opacity: 1;
}
.flash-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.typeIcon,
.closeIcon {
  display: flex;
  align-items: center;
}

.closeIcon {
  margin-left: 1rem;
  height: 100%;
  width: auto;
  font-size: 1.25rem;
  cursor: pointer;
}

.typeIcon {
  font-size: 2rem;
  margin-right: 1rem;
  height: 100%;
  width: auto;
}
