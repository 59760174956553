@import '../../../theme/Colors.scss';

input.fillWidth {
  padding: 0.5rem !important;
  border: 0.0625rem solid #e2e2e2 !important;
  border-radius: 0.35rem !important;
  background: white !important;
  line-height: 1.5rem !important;
  font-size: 0.95rem !important;
  color: #686f7f !important;
}

hr {
  margin: 32px 0px;
  border: none;
  border-top: 1px solid $lighterGrey;
}

@media screen and (max-width: 768px) {
  .formGroup-noMargin {
    margin-top: 2rem;
  }
}
