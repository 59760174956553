@import 'theme/Colors.scss';

.active-campaign__container {
  background-color: $white;
  padding: 40px 0;

  .active-campaign__sort-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .active-campaign__sort-divider {
      height: 2px;
      background: rgba(48, 48, 48, 0.1);
      width: calc(50% - 100px);
    }
    .active-campaign__sort {
      display: flex;
      align-items: center;
      margin: 0 20px;

      .active-campaign__sort-text {
        margin-right: 10px;
      }
    }
  }
  .active-campaign__grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 30px;
    margin-top: 40px;

    .active-campaign__grid-item {
      border-radius: 10px;
      background-color: $coolGrey1;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .active-campaign__container {
    .active-campaign__grid {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 20px;
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .active-campaign__container {
    padding: 30px 0;
    .active-campaign__sort-wrapper {
      .active-campaign__sort-divider {
        width: 25%;
      }
      .active-campaign__sort {
        margin: 0 5px;

        .active-campaign__sort-text {
          margin-right: 5px;
        }
      }
    }
    .active-campaign__grid {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media only screen and (max-width: 600px) {
  .active-campaign__container {
    .active-campaign__grid {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
