@import 'theme/Colors.scss';

.template-editor__navbar {
  height: 4rem;
  background: linear-gradient(90deg, #4e90d6, #4e50d6 53%, #7f66f1);
  flex: none;
  display: grid;
  align-items: center;
  padding: 0 1rem;
  z-index: 100;
  grid-template-columns: calc(449px - 1rem) 1fr 360px;
  color: $white;
  font-size: 14px;
  transition: all 0.2s ease-in-out;

  &.template-name-edit {
    grid-template-columns: 554px 1fr 200px;
  }

  .template-editor__navbar-left {
    display: flex;
    align-items: center;
    .navbar-left__exit {
      display: flex;
      align-items: center;
      height: 42px;
      padding-right: 1rem;
      font-weight: 500;
      &:hover {
        background: rgba(255, 255, 255, 0.15);
        border-radius: 2px;
      }
    }
  }

  .template-editor__navbar-center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .template-editor__navbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.75rem;
    .navbar-right__create-save-btn {
      border-radius: 2px;
      background: rgba(255, 255, 255, 0.1);
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
    }

    .navbar-right__preview-btn {
      color: $neutral700;
      border-radius: 2px;
    }
  }
}
