@import 'theme/Colors.scss';

.cmpContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 3.75rem 1fr;
  grid-template-areas: 'bulkAction card';

  width: 100%;
  height: auto;
  cursor: pointer;
}

.cmpContainer input[type='checkbox'] {
  margin-right: 1rem;
}

.bulkActions {
  display: table-cell;
  align-items: center;
  grid-area: bulkAction;
}

/* background-color and border-left is dynamically set based on data status passed into component */
.cmpCard {
  .cmpName-text {
    color: $neutral700;
  }
  &:not(.enabled) {
    .cmpName-text,
    .cmpStatus,
    .cmpDeliveries,
    .cmpStart,
    .cmpEnd,
    .cmpTemplate {
      font-weight: 400;
    }
  }

  /* disabled bold text for active campaign to match the style with Analytics page */
  // &.enabled {
  //   .cmpName-text {
  //     font-weight: 600;
  //   }
  // }
}

.cmpPriority,
.cmpStatus,
.cmpDeliveries,
.cmpStart,
.cmpEnd,
.cmpTemplate,
.cmpActions {
  // justify-content: center;
  align-items: center;
}

.cmpContainer .toggleSwitch {
  margin-right: 1rem;
}

.cmpCard li {
  align-items: center;
  height: 100%;
  border-left: 1px solid $coolGrey2;
  padding-left: 0.65rem;
}

.cmpCard li:first-child {
  min-width: 10rem;
  border-left: 0 solid $coolGrey2;
}

.campaign-card__drag-handle {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 12px;
  color: $greyDisabled;
}

.cmpName {
  display: flex;
}

.cmpPriority {
  grid-area: priority;
}

.cmpStatus {
  grid-area: enabled;
}

.cmpDeliveries {
  grid-area: delivery_limit;
}

.cmpStart {
  grid-area: start_date;
}

.cmpEnd {
  grid-area: end_date;
}

.cmpTemplate {
  position: absolute;
  grid-area: template;
  height: 100%;
}

.cmpActions {
  grid-area: actions;
}

.CampaignCard-template {
  display: flex;
}

.campaign-card__template-preview {
  max-width: 48px;
  max-height: 23px;
  margin: auto;
  margin-left: 8px;
  margin-bottom: -7px;
  border: 1px solid $lighterGrey;
  box-shadow: 0px 0px 8px $lighterGrey;
}

.campaign-card__template-preview-mobile {
  margin-top: 6px;
  margin-left: 8px;
  margin-bottom: 8px;
  border: 1px solid $lighterGrey;
  box-shadow: 0px 0px 8px $lighterGrey;
}

.cmpCard.TableRowCard .campaign-card__template-preview {
  max-width: 100px;
  max-height: 44px;
}

.campaign-card__template-tooltip {
  img {
    position: relative;
    width: 100%;
  }

  label {
    margin-top: 8px;
  }
}

.campaign-card__enabled-control {
  margin-right: 12px;
  align-items: center;

  > .toggleSwitch {
    position: relative;
    top: calc((26px - 20px) / 2);
  }
}

#campaign-card__form {
  .campaign-card__priority-textfield {
    input {
      padding-right: 0px;
      width: calc(100% - 41px);
    }
    .TextField-endIcon {
      margin-right: 0px;
      .campaign-card__priority-submit-btn,
      .campaign-card__priority-cancel-edit-btn,
      .campaign-card__priority-edit-btn {
        padding: 0px;
      }
    }
  }
}

.CampaignCard-priority-save {
  opacity: 1;
  transition: opacity 0.4s, color 0.4s;

  &:not(:disabled) {
    cursor: pointer;
  }

  &-notModified {
    opacity: 0;
    pointer-events: none;
  }
}

.CampaignCard {
  &:hover {
    .campaign-card__priority-edit-btn {
      opacity: 1;
    }
  }
}

.campaign-card__priority-edit-btn {
  opacity: 0;
  transition: opaicty 0.4s;
}

.cmpCard .cmpName {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 1160px) {
  .cmpCard .cmpTemplate .cmpCard .cmpActions {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .cmpCard .cmpTemplate button,
  .cmpCard .cmpActions button {
    padding: 0;
    width: auto;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 860px) {
  .cmpCard {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(
      4,
      auto
    ); // repeat(5, auto) with cmpActions is enabled.
    grid-template-areas:
      'name name'
      'enabled delivery_limit'
      'start_date end_date'
      'priority template';
    // "actions actions"; // Re-enabled if cmpActions is enabled.
  }
  .TableCard-flex {
    margin-top: 12px;
  }
  .cmpName-text {
    line-height: 18px;
  }
  .TableCard-table > tbody > tr > td {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}

@media only screen and (max-width: 414px) {
  .cmpCard {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, auto);
    grid-template-areas:
      'enabled enabled'
      'name name'
      'delivery_limit delivery_limit'
      'start_date start_date'
      'end_date end_date'
      'priority actions';
  }
  .cmpName-text {
    line-height: 18px;
  }
  .TableCard-table > tbody > tr > td {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}
