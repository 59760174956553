@import 'theme/Colors.scss';

.analytics-card {
  cursor: pointer;
  border: 0.5px solid rgba(48, 48, 48, 0.15);
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px rgba(146, 146, 146, 0.25);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 35rem;
  min-height: 35rem;

  .analytics-card__img-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 30%;

    .analytics-card__img {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .analytics-card__data-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    height: 70%;
    padding: 0 30px;

    .analytics-card__date {
      font-size: 12px;
      font-weight: 400;
    }

    .analytics-card__campaign-name {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 600;
      min-height: 4rem;
    }

    .analytics-card__info-container {
      margin-top: 12px;

      .analytics-card__status-container {
        display: flex;
        align-items: center;
        gap: 6px;

        .analytics-card__status-indicator {
          border-radius: 50%;
          width: 6px;
          height: 6px;
        }

        .analytics-card__status {
          font-size: 10px;
        }
      }

      .analytics-card__stats-container {
        display: flex;
        flex-direction: column;
        align-items: space-between;

        .analytics-card__stats {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;

          .stats__title {
            font-size: 14px;
            font-weight: 400;
          }

          .bold {
            font-weight: 700;
          }

          .semi-bold {
            font-weight: 500;
          }
        }

        .analytics-card__divider {
          height: 0.5px;
          background: rgba(48, 48, 48, 0.6);
          margin: 20px 0;
        }
      }
    }
  }
}

.ellipsis-span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.analytics-td {
  .chakra-menu__menuitem {
    display: block;
    padding: 5px;
    text-align: center;
  }
  .chakra-menu__menu-list {
    min-width: 60px;
  }
  .chakra-menu__menu-button {
    background-color: $white;
    height: 30px;
    padding: 8px 10px;
    margin-left: 10px;
    font-weight: 400;
  }
}

.chakra-portal > div > div {
  border-radius: 10px !important;
}
