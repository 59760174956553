@import 'theme/Colors.scss';

.header-container {
  display: grid;
  gap: 0 0.5rem;
  grid-template-columns: 14.25rem 1fr auto;
  grid-template-areas: 'brand companySummary authBtn';
  height: 100%;

  .brand {
    grid-area: brand;
    height: 100%;

    .nav-brand-mobile {
      display: flex;
      align-items: center;
      height: 100%;

      .nav-brand__logo {
        display: block;
        max-width: 108px;
        max-height: 36px;
        width: auto;
        height: auto;
      }
    }
  }

  .authBtn {
    display: flex;
    align-items: center;
    grid-area: authBtn;
    text-align: right;
    width: 100%;
  
    > button {
      justify-content: end;
      margin-right: -10px;
      font-weight: 400;
    }
  }
}

.companyName {
  font-size: 1rem;
}

.hamburger-menu {
  position: absolute;
  z-index: 3;
  top: 1.45rem;
  left: 1.25rem;
  font-size: 1.25rem;
  min-width: 1.25rem;
  width: fit-content;
  cursor: pointer;
}

.companySummary {
  display: inline-flex;
  align-items: center;
  font-size: 1.25rem;
  grid-area: companySummary;
}

.companySummary.resetPassword {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  grid-area: companySummary;
}

.companySummary .fas,
.companySummary .far,
.companySummary .fal {
  margin-right: 0.5rem;
}

.companySummary button {
  margin-left: 1rem;
  width: auto;
}

.companySummary button i::before {
  font-size: 1.5rem;
}

.companySummary p {
  margin: 0.15rem 0;
}

.companySummary .name .fas {
  cursor: pointer;
  font-size: 1.12rem;
}

.companySummary .name .fas:hover {
  color: $secondary900;
  cursor: pointer;
}

.companySummary .email {
  font-size: 0.85rem;
}

/* ============ Media Queries ============= */
@media only screen and (max-width: 690px) {
  .header-container {
    grid-template-areas:
      'brand authBtn'
      'companySummary companySummary';
    display: flex;
    align-items: center;

    .authBtn button {
      justify-content: flex-end;
      margin-left: auto;
      width: auto !important;
    }
  }

  .companySummary {
    margin-top: 0;
  }

  .companySummary p {
    display: flex;
    align-items: center;
  }

  .companySummary .name {
    font-size: 0.9rem;
  }

  .companySummary .authBtn {
    font-size: 1rem;
  }

  .companySummary .authBtn i::before {
    font-size: 1.25rem;
  }
}
